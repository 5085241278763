@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap);
@import url(https://platform.cj.com/design-tokens.css);
@import url(//fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700);
:root {
    /* Directly named colors */
    --cj-green: #49c581;
    --dark-grey-blue: #254a5d;
    --greeny-blue: #49c5b1;
    --dark: #151c23;
    --gunmetal: #424c56;
    --black: #333333;
    --warm-grey: #757575;
    --cool-grey: #babbbd;
    --silver: #d8dcdf;
    --nice-blue: #1a6fba;
    --butterscotch: #ffb038;
    --bluey-green: #2bb179;
    --pastel-red: #e25959;
    --white: #ffffff;
  
    /* Maintainable Naming Convention */
    /*   1) order (example: primary, secondary, tertiary, quaternary, quinary, etc) */
    /*   2) role (example: brand, button, table-heading, etc) */
    /*   3) state (example: active, inactive, default, disabled, on-hover, etc) */
    /*   4) css property (example: color, font-family, margin, etc) */
  
    --primary-brand-color: var(--cj-green);
    --primary-button-color: var(--cj-green);
  
    --primary-body-text-color: var(--black);
    --heading-color: var(--black);
    --table-heading-active-color: var(--black);
    --svg-fill-on-hover-color: var(--black);
  
    --secondary-body-text-color: var(--warm-grey);
    --table-heading-defualt-color: var(--warm-grey);
    --table-heading-inactive-color: var(--warm-grey);
    --svg-fill-defualt-color: var(--warm-grey);
  
    --text-link-color: var(--nice-blue);
  
    --panel-color: var(--white);
    --container-color: var(--white);
    --primary-light-text-color: var(--white);
  
    --page-background-color: #f6f6f6;
    --table-row-highlight: #f6f6f6;
  
    --border-color: var(--silver);
  
    --button-background-disabled-color: #edeef0;
  
    --button-text-disabled-color: #a7a7a7;
  
    --badge-color: #6d7684;
    --indicator-color: #6d7684;
  
    --dialog-window-background-color: #4d5051;
  
    --light-text-color: #bebfc0;
  
    --validation-success-color: var(--bluey-green);
  
    --validation-error-color: var(--pastel-red);
  
    --primary-font-family: "Roboto", "-system-ui", "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    --spacing-xs: var(--spacing-2);
    --spacing-small: var(--spacing-4);
    --spacing-medium: var(--spacing-8);
    --spacing-default: var(--spacing-medium);
    --spacing-medium-large: var(--spacing-12);
    --spacing-large: var(--spacing-16);
    --spacing-xl: var(--spacing-24);
    --spacing-2xl: var(--spacing-32);
    --spacing-3xl: var(--spacing-40);
    --spacing-4xl: var(--spacing-48);
    --spacing-5xl: var(--spacing-64);
    --spacing-6xl: var(--spacing-80);
  }
  
.vsx-styled-form * {
  box-sizing: border-box;
}
.vsx-styled-form fieldset p {
  position: relative;
  display: block;
  overflow: hidden;
}
.vsx-styled-form p > label {
  float: left;
  padding: 0 0 0 18px;
}
.vsx-styled-form label {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 500;
  max-width: 100%;
  margin: 0 0 0.4rem;
}
.vsx-styled-form label span {
  font-size: 1.4rem;
  font-weight: 400;
}
.vsx-styled-form fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.vsx-styled-form legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  line-height: inherit;
  color: var(--color-text-default);
  border: 0;
  border-bottom: 1px solid #e1e1e1;
}
.vsx-styled-form select,
.vsx-styled-form textarea,
.vsx-styled-form input[type=file],
.vsx-styled-form input[type=text],
.vsx-styled-form input[type=password],
.vsx-styled-form input[type=date],
.vsx-styled-form input[type=datetime],
.vsx-styled-form input[type=datetime-local],
.vsx-styled-form input[type=month],
.vsx-styled-form input[type=time],
.vsx-styled-form input[type=week],
.vsx-styled-form input[type=number],
.vsx-styled-form input[type=email],
.vsx-styled-form input[type=url],
.vsx-styled-form input[type=search],
.vsx-styled-form input[type=tel],
.vsx-styled-form input[type=color] {
  background-color: var(--color-background-default);
  border: 1px solid #c8ccce;
  border-radius: 3px;
  color: var(--color-text-default);
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  height: 32px;
  line-height: 1.6;
  max-width: 100%;
  padding: 2px 8px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
.vsx-styled-form select:focus,
.vsx-styled-form textarea:focus,
.vsx-styled-form input[type=file]:focus,
.vsx-styled-form input[type=text]:focus,
.vsx-styled-form input[type=password]:focus,
.vsx-styled-form input[type=datetime]:focus,
.vsx-styled-form input[type=datetime-local]:focus,
.vsx-styled-form input[type=date]:focus,
.vsx-styled-form input[type=month]:focus,
.vsx-styled-form input[type=time]:focus,
.vsx-styled-form input[type=week]:focus,
.vsx-styled-form input[type=number]:focus,
.vsx-styled-form input[type=email]:focus,
.vsx-styled-form input[type=url]:focus,
.vsx-styled-form input[type=search]:focus,
.vsx-styled-form input[type=tel]:focus,
.vsx-styled-form input[type=color]:focus {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-styled-form select.input-error,
.vsx-styled-form textarea.input-error,
.vsx-styled-form input.input-error[type=file],
.vsx-styled-form input.input-error[type=text],
.vsx-styled-form input.input-error[type=password],
.vsx-styled-form input.input-error[type=datetime],
.vsx-styled-form input.input-error[type=datetime-local],
.vsx-styled-form input.input-error[type=date],
.vsx-styled-form input.input-error[type=month],
.vsx-styled-form input.input-error[type=time],
.vsx-styled-form input.input-error[type=week],
.vsx-styled-form input.input-error[type=number],
.vsx-styled-form input.input-error[type=email],
.vsx-styled-form input.input-error[type=url],
.vsx-styled-form input.input-error[type=search],
.vsx-styled-form input.input-error[type=tel],
.vsx-styled-form input.input-error[type=color] {
  border-color: var(--color-border-outline-error);
  box-shadow: 0 0 4px rgba(225, 82, 61, 0.4);
}
.vsx-styled-form input[type=radio] + label,
.vsx-styled-form input[type=checkbox] + label {
  font-weight: 400;
  margin: 0 1.6rem 0 0;
}
.vsx-styled-form input[type=radio],
.vsx-styled-form input[type=checkbox] {
  margin: 0.6rem 0.8rem 0.8rem 0;
  line-height: normal;
}
.vsx-styled-form input[type=radio]:focus,
.vsx-styled-form input[type=checkbox]:focus {
  outline: thin dotted 9;
  outline: 4px auto -webkit-focus-ring-color;
  /*   outline-offset: -2px; */
}
.vsx-styled-form input[type=color] {
  border-radius: 2px;
  padding: 0 2px;
  height: 24px;
  width: 24px;
}
.vsx-styled-form input[type=file] {
  display: block;
  font-size: 1.2rem;
  height: auto;
  padding: 4px;
}
.vsx-styled-form input[type=range] {
  display: block;
  padding: 4px;
  width: 100%;
}
.vsx-styled-form optgroup {
  font-weight: bold;
}
.vsx-styled-form output {
  display: block;
  padding-top: 8px;
  font-size: 1.4rem;
  line-height: 1.6;
  color: var(--color-text-default);
}
.vsx-styled-form progress {
  display: block;
  width: 100%;
}
.vsx-styled-form select[multiple],
.vsx-styled-form select[size] {
  height: auto;
}
.vsx-styled-form textarea {
  overflow: auto;
  min-height: 64px;
}
.vsx-styled-form input[disabled],
.vsx-styled-form select[disabled],
.vsx-styled-form textarea[disabled],
.vsx-styled-form input[readonly],
.vsx-styled-form select[readonly],
.vsx-styled-form textarea[readonly] {
  color: #888;
  cursor: not-allowed;
  background-color: #eee;
  border-color: #e1e1e1;
}
.vsx-styled-form input[type=radio][disabled],
.vsx-styled-form input[type=checkbox][disabled],
.vsx-styled-form input[type=radio].disabled,
.vsx-styled-form input[type=checkbox].disabled,
.vsx-styled-form fieldset[disabled] input[type=radio],
.vsx-styled-form fieldset[disabled] input[type=checkbox] {
  cursor: not-allowed;
}
.vsx-styled-form ::-webkit-input-placeholder {
  color: #999;
  opacity: 1;
}
.vsx-styled-form ::placeholder {
  color: #999;
  opacity: 1;
}
/* @license
 * MyFonts Webfont Build ID 2691808, 2013-11-19T16:06:51-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Gibson Light by Canada Type
 * URL: http://www.myfonts.com/fonts/canadatype/gibson/light/

 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2691808
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2011 Rod McDonald. Published by Canada Type. All rights reserved.
 *
 * Â© 2013 MyFonts Inc
*/
@font-face {
  font-family: 'gibson-light';
  src: url(/static/media/gibson-light-webfont.a87665d3.woff2) format('woff2'),
    url(/static/media/gibson-light-webfont.ba481e12.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

/* @import url('//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800'); */

/*
GLOBAL COLOR VARIABLES:
border-color: #c8ccce;
Brand Primary: #49c5b1
Brand Dark Blue: #151c23
Pink <code>: #eb3366
*/

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: scrollbar;
}

body {
  background-color: var(--color-background-page);
  color: var(--color-text-default);
  font-family: 'Roboto', -system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

::selection {
  background-color: rgba(73, 197, 176, 0.4);
  color: #000;
  text-shadow: none;
}

::-moz-selection {
  background-color: rgba(73, 197, 176, 0.4);
  color: #000;
  text-shadow: none;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

.vsx-mdi-icon {
  display: inline-flex;
  font-size: 1em;
  width: 1em;
  height: 1em;
  place-items: center;
  place-content: center;
}
.vsx-mdi-icon > svg {
  margin: 0px auto;
}
.vsx-mdi-icon.vsx-mdi-icon-clickable {
  cursor: pointer;
  transition: all 0.1s;
}
.vsx-mdi-icon.vsx-mdi-icon-clickable:hover {
  color: white;
  background-color: grey;
  border-radius: 3px;
}
.vsx-mdi-icon .vsx-beat {
  -webkit-animation-name: vsx-beat;
  animation-name: vsx-beat;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--vsx-animation-delay, 0s);
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--vsx-animation-direction, normal);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--vsx-animation-duration, 1s);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: var(--vsx-animation-timing, ease-in-out);
  animation-timing-function: ease-in-out;
  animation-timing-function: var(--vsx-animation-timing, ease-in-out);
}
.vsx-mdi-icon .vsx-bounce {
  -webkit-animation-name: vsx-bounce;
  animation-name: vsx-bounce;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--vsx-animation-delay, 0s);
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--vsx-animation-direction, normal);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--vsx-animation-duration, 1s);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  -webkit-animation-timing-function: var(--vsx-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  animation-timing-function: var(--vsx-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}
.vsx-mdi-icon .vsx-fade {
  -webkit-animation-name: vsx-fade;
  animation-name: vsx-fade;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--vsx-animation-delay, 0s);
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--vsx-animation-direction, normal);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--vsx-animation-duration, 1s);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  -webkit-animation-timing-function: var(--vsx-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  animation-timing-function: var(--vsx-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}
.vsx-mdi-icon .vsx-beat-fade {
  -webkit-animation-name: vsx-beat-fade;
  animation-name: vsx-beat-fade;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--vsx-animation-delay, 0s);
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--vsx-animation-direction, normal);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--vsx-animation-duration, 1s);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  -webkit-animation-timing-function: var(--vsx-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  animation-timing-function: var(--vsx-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}
.vsx-mdi-icon .vsx-flip {
  -webkit-animation-name: vsx-flip;
  animation-name: vsx-flip;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--vsx-animation-delay, 0s);
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--vsx-animation-direction, normal);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--vsx-animation-duration, 1s);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: var(--vsx-animation-timing, ease-in-out);
  animation-timing-function: ease-in-out;
  animation-timing-function: var(--vsx-animation-timing, ease-in-out);
}
.vsx-mdi-icon .vsx-shake {
  -webkit-animation-name: vsx-shake;
  animation-name: vsx-shake;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--vsx-animation-delay, 0s);
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--vsx-animation-direction, normal);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--vsx-animation-duration, 1s);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  -webkit-animation-timing-function: linear;
  -webkit-animation-timing-function: var(--vsx-animation-timing, linear);
  animation-timing-function: linear;
  animation-timing-function: var(--vsx-animation-timing, linear);
}
.vsx-mdi-icon .vsx-spin {
  -webkit-animation-name: vsx-spin;
  animation-name: vsx-spin;
  -webkit-animation-delay: 0s;
  -webkit-animation-delay: var(--vsx-animation-delay, 0s);
  animation-delay: 0s;
  animation-delay: var(--vsx-animation-delay, 0s);
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--vsx-animation-direction, normal);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  -webkit-animation-duration: 2s;
  -webkit-animation-duration: var(--vsx-animation-duration, 2s);
  animation-duration: 2s;
  animation-duration: var(--vsx-animation-duration, 2s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  -webkit-animation-timing-function: linear;
  -webkit-animation-timing-function: var(--vsx-animation-timing, linear);
  animation-timing-function: linear;
  animation-timing-function: var(--vsx-animation-timing, linear);
}
.vsx-mdi-icon .vsx-reverse {
  --vsx-animation-direction: reverse;
}
.vsx-mdi-icon .vsx-pulse,
.vsx-mdi-icon .vsx-spin-pulse {
  -webkit-animation-name: vsx-spin;
  animation-name: vsx-spin;
  -webkit-animation-direction: normal;
  -webkit-animation-direction: var(--vsx-animation-direction, normal);
  animation-direction: normal;
  animation-direction: var(--vsx-animation-direction, normal);
  -webkit-animation-duration: 1s;
  -webkit-animation-duration: var(--vsx-animation-duration, 1s);
  animation-duration: 1s;
  animation-duration: var(--vsx-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--vsx-animation-iteration-count, infinite);
  -webkit-animation-timing-function: steps(8);
  -webkit-animation-timing-function: var(--vsx-animation-timing, steps(8));
  animation-timing-function: steps(8);
  animation-timing-function: var(--vsx-animation-timing, steps(8));
}
@media (prefers-reduced-motion: reduce) {
  .vsx-mdi-icon .vsx-beat,
.vsx-mdi-icon .vsx-bounce,
.vsx-mdi-icon .vsx-fade,
.vsx-mdi-icon .vsx-beat-fade,
.vsx-mdi-icon .vsx-flip,
.vsx-mdi-icon .vsx-pulse,
.vsx-mdi-icon .vsx-shake,
.vsx-mdi-icon .vsx-spin,
.vsx-mdi-icon .vsx-spin-pulse {
    -webkit-animation-delay: -1ms;
    animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
    animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@-webkit-keyframes vsx-beat {
  0%, 90% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  45% {
    -webkit-transform: scale(1.25);
    -webkit-transform: scale(var(--vsx-beat-scale, 1.25));
    transform: scale(1.25);
    transform: scale(var(--vsx-beat-scale, 1.25));
  }
}
@keyframes vsx-beat {
  0%, 90% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  45% {
    -webkit-transform: scale(1.25);
    -webkit-transform: scale(var(--vsx-beat-scale, 1.25));
    transform: scale(1.25);
    transform: scale(var(--vsx-beat-scale, 1.25));
  }
}
@-webkit-keyframes vsx-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
    -webkit-transform: scale(var(--vsx-bounce-start-scale-x, 1.1), var(--vsx-bounce-start-scale-y, 0.9)) translateY(0);
    transform: scale(1.1, 0.9) translateY(0);
    transform: scale(var(--vsx-bounce-start-scale-x, 1.1), var(--vsx-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    -webkit-transform: scale(0.9, 1.1) translateY(-0.5em);
    -webkit-transform: scale(var(--vsx-bounce-jump-scale-x, 0.9), var(--vsx-bounce-jump-scale-y, 1.1)) translateY(var(--vsx-bounce-height, -0.5em));
    transform: scale(0.9, 1.1) translateY(-0.5em);
    transform: scale(var(--vsx-bounce-jump-scale-x, 0.9), var(--vsx-bounce-jump-scale-y, 1.1)) translateY(var(--vsx-bounce-height, -0.5em));
  }
  50% {
    -webkit-transform: scale(1.05, 0.95) translateY(0);
    -webkit-transform: scale(var(--vsx-bounce-land-scale-x, 1.05), var(--vsx-bounce-land-scale-y, 0.95)) translateY(0);
    transform: scale(1.05, 0.95) translateY(0);
    transform: scale(var(--vsx-bounce-land-scale-x, 1.05), var(--vsx-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(-0.125em);
    -webkit-transform: scale(1, 1) translateY(var(--vsx-bounce-rebound, -0.125em));
    transform: scale(1, 1) translateY(-0.125em);
    transform: scale(1, 1) translateY(var(--vsx-bounce-rebound, -0.125em));
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes vsx-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
    -webkit-transform: scale(var(--vsx-bounce-start-scale-x, 1.1), var(--vsx-bounce-start-scale-y, 0.9)) translateY(0);
    transform: scale(1.1, 0.9) translateY(0);
    transform: scale(var(--vsx-bounce-start-scale-x, 1.1), var(--vsx-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    -webkit-transform: scale(0.9, 1.1) translateY(-0.5em);
    -webkit-transform: scale(var(--vsx-bounce-jump-scale-x, 0.9), var(--vsx-bounce-jump-scale-y, 1.1)) translateY(var(--vsx-bounce-height, -0.5em));
    transform: scale(0.9, 1.1) translateY(-0.5em);
    transform: scale(var(--vsx-bounce-jump-scale-x, 0.9), var(--vsx-bounce-jump-scale-y, 1.1)) translateY(var(--vsx-bounce-height, -0.5em));
  }
  50% {
    -webkit-transform: scale(1.05, 0.95) translateY(0);
    -webkit-transform: scale(var(--vsx-bounce-land-scale-x, 1.05), var(--vsx-bounce-land-scale-y, 0.95)) translateY(0);
    transform: scale(1.05, 0.95) translateY(0);
    transform: scale(var(--vsx-bounce-land-scale-x, 1.05), var(--vsx-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(-0.125em);
    -webkit-transform: scale(1, 1) translateY(var(--vsx-bounce-rebound, -0.125em));
    transform: scale(1, 1) translateY(-0.125em);
    transform: scale(1, 1) translateY(var(--vsx-bounce-rebound, -0.125em));
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
}
@-webkit-keyframes vsx-fade {
  50% {
    opacity: 0.4;
    opacity: var(--vsx-fade-opacity, 0.4);
  }
}
@keyframes vsx-fade {
  50% {
    opacity: 0.4;
    opacity: var(--vsx-fade-opacity, 0.4);
  }
}
@-webkit-keyframes vsx-beat-fade {
  0%, 100% {
    opacity: 0.4;
    opacity: var(--vsx-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.125);
    -webkit-transform: scale(var(--vsx-beat-fade-scale, 1.125));
    transform: scale(1.125);
    transform: scale(var(--vsx-beat-fade-scale, 1.125));
  }
}
@keyframes vsx-beat-fade {
  0%, 100% {
    opacity: 0.4;
    opacity: var(--vsx-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.125);
    -webkit-transform: scale(var(--vsx-beat-fade-scale, 1.125));
    transform: scale(1.125);
    transform: scale(var(--vsx-beat-fade-scale, 1.125));
  }
}
@-webkit-keyframes vsx-flip {
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -180deg);
    -webkit-transform: rotate3d(var(--vsx-flip-x, 0), var(--vsx-flip-y, 1), var(--vsx-flip-z, 0), var(--vsx-flip-angle, -180deg));
    transform: rotate3d(0, 1, 0, -180deg);
    transform: rotate3d(var(--vsx-flip-x, 0), var(--vsx-flip-y, 1), var(--vsx-flip-z, 0), var(--vsx-flip-angle, -180deg));
  }
}
@keyframes vsx-flip {
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -180deg);
    -webkit-transform: rotate3d(var(--vsx-flip-x, 0), var(--vsx-flip-y, 1), var(--vsx-flip-z, 0), var(--vsx-flip-angle, -180deg));
    transform: rotate3d(0, 1, 0, -180deg);
    transform: rotate3d(var(--vsx-flip-x, 0), var(--vsx-flip-y, 1), var(--vsx-flip-z, 0), var(--vsx-flip-angle, -180deg));
  }
}
@-webkit-keyframes vsx-shake {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  4% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  20% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }
  32% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  36% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  40%, 100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes vsx-shake {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  4% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  20% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }
  32% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  36% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  40%, 100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-webkit-keyframes vsx-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes vsx-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.vsx-spinner.vsx-spinner-small {
  padding: 1em;
}

.vsx-spinner.vsx-spinner-large {
  padding: 1.2em;
}

.vsx-spinner.vsx-spinner-extra-large {
  padding: 1.4em;
}

.vsx-spinner-button span {
  padding: 0.3em;
}

.vsx-spinner-no-pad.vsx-spinner-no-pad {
  padding: 0;
}

.vsx-spinner-inline-block {
  display: inline-block;
}

.vsx-layout-section {
  --separator: 1px solid var(--color-border-light);
  border-top: var(--separator);
  border-bottom: var(--separator);
}
.vsx-layout-section:not(.disable-form-page-block-adjust) {
  margin-left: -32px;
  margin-right: -32px;
}
.vsx-layout-section .vsx-layout-section-title {
  font-size: var(--font-font-size-14);
  font-weight: var(--font-font-weight-medium);
  cursor: pointer;
}
.vsx-layout-section .vsx-layout-section-children {
  padding: 16px 32px;
}

.vsx-layout-section:has(+ .vsx-layout-section) {
  border-bottom-width: 0;
}
.vsx-btn {
  font-family: "Roboto";
  box-sizing: border-box;
  border-collapse: separate;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: var(--color-text-default);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  height: 32px;
  min-width: -webkit-min-content;
  min-width: min-content;
  overflow: hidden;
  padding: 4px 12px;
  text-align: center;
  text-shadow: none;
  transition: 0.2s all ease-in-out;
  vertical-align: middle;
  width: auto;
  white-space: nowrap;
  position: relative;
}
.vsx-btn:focus-visible {
  outline: auto;
}
.vsx-btn:visited {
  text-decoration: underline;
}
.vsx-btn:disabled {
  cursor: not-allowed;
}
.vsx-btn .vsx-btn-child.vsx-btn-child-hidden {
  opacity: 0;
}
.vsx-btn .vsx-btn-spinner {
  position: absolute;
  display: flex;
}
.vsx-btn.uppercase {
  text-transform: uppercase;
}
.vsx-btn.sm {
  font-size: 1rem;
  height: auto;
  line-height: 1;
  padding: 6px 8px;
}
.vsx-btn.lg {
  padding: 12px 28px;
  height: 40px;
  line-height: 1;
}
.vsx-btn.primary {
  border-color: var(--color-background-button-primary-default);
  background: var(--color-background-button-primary-default);
  color: var(--color-text-white);
}
.vsx-btn.primary:focus-visible {
  outline-color: var(--color-background-primary-default);
}
.vsx-btn.primary:hover:not(:disabled) {
  background: var(--color-background-button-primary-hovered);
}
.vsx-btn.primary:active:not(:disabled) {
  background: var(--color-background-button-primary-active);
  box-shadow: 0 0 0 2px var(--color-background-primary-light);
}
.vsx-btn.primary:disabled {
  background: var(--color-background-button-primary-disabled);
  color: var(--color-deprecating-vs-primary-text-disabled);
}
.vsx-btn.secondary {
  background: var(--color-background-default);
  border-color: var(--color-border-outline-subdued);
  color: var(--color-text-subdued);
}
.vsx-btn.secondary:hover:not(:disabled) {
  border-color: var(--color-border-outline-dark);
  color: var(--color-text-default);
}
.vsx-btn.secondary:active:not(:disabled) {
  border-color: var(--color-border-outline-dark);
  color: var(--color-text-default);
  box-shadow: 0 0 0 2px var(--color-background-light-2);
}
.vsx-btn.secondary:disabled {
  background: var(--color-background-disabled);
  border-color: var(--color-border-light);
  color: var(--color-text-subdued-light);
}
.vsx-btn.tertiary {
  border-color: transparent;
  background: var(--color-background-transparent);
  color: var(--color-text-subdued);
}
.vsx-btn.tertiary:hover:not(:disabled) {
  background: var(--color-background-light-2);
  color: var(--color-text-default);
}
.vsx-btn.tertiary:active:not(:disabled) {
  background: var(--color-background-light-2);
  box-shadow: 0 0 0 2px var(--color-background-light-3);
}
.vsx-btn.tertiary:disabled {
  color: var(--color-text-subdued-light);
}
.vsx-btn.link {
  border-color: transparent;
  background: var(--color-background-transparent);
  color: var(--color-text-link-default);
}
.vsx-btn.link:hover:not(:disabled) {
  background: var(--color-background-light-2);
  color: var(--color-text-link-hover);
}
.vsx-btn.link:active:not(:disabled) {
  background: var(--color-background-light-2);
  box-shadow: 0 0 0 2px var(--color-background-light-3);
}
.vsx-btn.link:disabled {
  color: var(--color-text-subdued-light);
}
.vsx-icon-btn {
  min-width: 32px;
  min-height: 32px;
  height: 32px;
  font-size: 20px;
  padding: 0;
}
.vsx-icon-btn.free-size {
  min-width: unset;
  min-height: unset;
  font-size: unset;
  line-height: unset;
  width: unset;
  height: unset;
}
.vsx-icon-btn.tertiary {
  border-radius: 3px;
}
.vsx-icon-btn.tertiary.rounded {
  border-radius: var(--border-radius-circle);
}
.vsx-icon-btn > .vsx-mdi-icon {
  align-self: center;
  margin: 0 auto;
}
.tippy-box[data-animation=fade][data-state=hidden]{opacity:0}[data-tippy-root]{max-width:calc(100vw - 10px)}.tippy-box{position:relative;background-color:#333;color:#fff;border-radius:4px;font-size:14px;line-height:1.4;white-space:normal;outline:0;transition-property:transform,visibility,opacity}.tippy-box[data-placement^=top]>.tippy-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-arrow:before{bottom:-7px;left:0;border-width:8px 8px 0;border-top-color:initial;transform-origin:center top}.tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:initial;transform-origin:center bottom}.tippy-box[data-placement^=left]>.tippy-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:initial;right:-7px;transform-origin:center left}.tippy-box[data-placement^=right]>.tippy-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:initial;transform-origin:center right}.tippy-box[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}.tippy-arrow{width:16px;height:16px;color:#333}.tippy-arrow:before{content:"";position:absolute;border-color:transparent;border-style:solid}.tippy-content{position:relative;padding:5px 9px;z-index:1}
.vsx-popover-wrapper, .vsx-popover-reference {
  display: inline-flex;
  flex-direction: column;
}

.vsx-popover {
  box-shadow: 1px 4px 8px 1px var(--color-background-backdrop);
  text-align: left;
}
.vsx-popover.vsx-popover-animate {
  -webkit-animation: fade-in 0.2s ease-out;
          animation: fade-in 0.2s ease-out;
}
.vsx-popover.vsx-popover-success {
  --vsx-popover-colored-border-color: var(--color-background-button-primary-default);
}
.vsx-popover.vsx-popover-info {
  --vsx-popover-colored-border-color: var(--color-icon-default);
}
.vsx-popover.vsx-popover-warning {
  --vsx-popover-colored-border-color: var(--color-icon-critical);
}
.vsx-popover .tippy-content {
  color: var(--color-text-default);
  background-color: var(--color-background-default);
  padding: 12px;
  border-radius: 3px;
  border-left: 4px solid var(--vsx-popover-colored-border-color);
}
.vsx-popover .tippy-content > div:first-child {
  display: flex;
  place-content: center;
  place-items: center;
}
.vsx-popover[data-placement^=bottom] > .tippy-arrow:before {
  border-bottom-color: var(--color-background-default);
}
.vsx-popover[data-placement^=top] > .tippy-arrow:before {
  border-top-color: var(--color-background-default);
}
.vsx-popover[data-placement^=left] > .tippy-arrow:before {
  border-left-color: var(--color-background-default);
}
.vsx-popover[data-placement^=right] > .tippy-arrow:before {
  border-right-color: var(--vsx-popover-colored-border-color);
}
.vsx-popover .tippy-arrow {
  color: var(--color-background-default);
}

.vsx-tooltip.vsx-popover {
  --vsx-popover-colored-border-color: var(--color-icon-on-default);
}
.vsx-tooltip.vsx-popover .tippy-content {
  color: var(--color-white);
  background-color: var(--color-icon-on-default);
  border-left: unset;
  padding: 7px 8px;
  font-size: 11px;
  font-weight: normal;
}
.vsx-tooltip.vsx-popover[data-placement^=bottom] > .tippy-arrow:before {
  border-bottom-color: var(--vsx-popover-colored-border-color);
}
.vsx-tooltip.vsx-popover[data-placement^=top] > .tippy-arrow:before {
  border-top-color: var(--vsx-popover-colored-border-color);
}
.vsx-tooltip.vsx-popover[data-placement^=left] > .tippy-arrow:before {
  border-left-color: var(--vsx-popover-colored-border-color);
}
.vsx-tooltip.vsx-popover[data-placement^=right] > .tippy-arrow:before {
  border-right-color: var(--vsx-popover-colored-border-color);
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.form-horizontal .form-group-vertical {
  margin-right: 0;
  margin-left: 0;
}

.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  display: table;
  content: ' ';
}

.form-horizontal .form-group:after {
  clear: both;
}

.form-group-buttons {
  text-align: right;
}

.form-group-buttons .form-button {
  margin-left: 10px;
}

.form-label-vertical {
  margin-bottom: 0.3em;
}

.form-label-text-vertical {
  font-size: 1.1em;
}

.form-group-static-text {
  line-height: 32px;
  padding: 1px 6px;
}

.form-group-required-sign {
  padding-left: 0.3em;
  padding-right: 0.3em;
  color: var(--color-text-critical);
}

.form-group-error-span {
  display: block;
  color: var(--color-text-critical);
  top: 6px;
  position: relative;
}

.has-error .control-label {
  color: var(--color-text-critical);
}

.has-error .form-control {
  border-color: var(--color-border-outline-error);
}

.form-group-radio-label {
  font-size: 1em;
  cursor: pointer;
  cursor: hand;
}

.cj-form-input.form-group-radio-input {
  min-height: 24px;
  margin-bottom: 0;
}

.has-help-icon {
  display: inline;
  overflow: visible;
  float: left;
}

.help-icon-form-component {
  margin-left: 15px;
}

.clipboard-input {
  color: #9e868e;
  background-color: inherit;
  cursor: default;
}

.clipboard-button {
  padding-top: 6px;
  padding-right: 12px;
}

.clipboard-button:focus {
  outline: 0;
}

.row-destinationUrl .tooltip-inner {
  width: 350px;
  max-width: 350px;
}

.fieldset-title {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
}

.fieldset-panel {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
}

fieldset.group {
  margin: 0;
  margin-bottom: 1.25em;
  padding: 0.125em;
}

fieldset.group ul.checkbox {
  margin: 0;
  padding: 0;
  margin-left: 20px;
  list-style: none;
}

fieldset.group ul.checkbox li input {
  margin-right: 0.25em;
}

fieldset.group ul.checkbox li {
  border: 1px transparent solid;
  display: inline-block;
  width: 12em;
}

fieldset.group ul.checkbox li:hover,
fieldset.group ul.checkbox li.focus {
  background-color: lightyellow;
  border: 1px gray solid;
  width: 12em;
}

.vsx-validation-error {
  color: var(--color-text-critical);
  margin-top: 4px;
  font-size: 1.3rem;
}

.vsx-field-help {
  color: #777;
  margin-top: 4px;
  font-size: 1.3rem;
}

.vsx-field-optional {
  color: #777;
  font-size: 1.3rem;
  font-style: italic;
}

.vsx-label-font-bold {
  font-weight: 500;
}

.vsx-label-font-normal {
  font-weight: normal;
}

.vsx-field .form-group-required-sign {
  padding-left: 0;
}

.vsx-react-select.vsx-react-select.vsx-react-select.vsx-react-select-error {
  box-shadow: 0 0 5px var(--color-text-critical);
}
.vsx-react-select.vsx-react-select.vsx-react-select.vsx-react-select-error .vsx-react-select-control {
  border-color: var(--color-text-critical);
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-control {
  border: 1px solid #c8ccce;
  min-height: 30px;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-control-disabled {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}
.vsx-react-select.vsx-react-select.vsx-react-select.focus-styles .vsx-react-select-control-focused {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-indicators-container {
  min-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-clear-indicator {
  padding: 0;
  margin: 0 3px;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-clear-indicator .vsx-mdi-icon:hover {
  color: white;
  background-color: grey;
  border-radius: 3px;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-loading-indicator {
  padding: 0;
  margin: 0 6px;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-dropdown-indicator {
  padding: 0 2px;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-indicator-separator {
  margin-top: 0;
  margin-bottom: 0;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-multivalue-remove:hover {
  color: unset;
  background-color: unset;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-multivalue-remove .vsx-mdi-icon:hover {
  color: white;
  background-color: grey;
  border-radius: 3px;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-menu-list {
  padding-top: 0;
  padding-bottom: 0;
}
.vsx-react-select.vsx-react-select.vsx-react-select .vsx-react-select-option-selected {
  color: unset;
}
/* START BOOTSTRAP STYLE TRANSFER (need to cleanup) */
.panel {
  margin-bottom: 2.4rem;
  background-color: var(--color-background-default);
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 2px 3px -1px rgba(0, 0, 0, 0.08);
}
.panel-body {
  padding: 16px;
}
.panel-heading {
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 12px 16px;
}
.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}
.panel-title {
  color: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
}
.panel-title > a,
.panel-title > small,
.panel-title > .small,
.panel-title > small > a,
.panel-title > .small > a {
  color: inherit;
}
.panel-footer {
  /*   background-color: #f9f9f9; */
  /*   border-top: 1px solid #ddd; */
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 1.3rem;
  padding: 16px;
}
.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child,
.panel
  > .panel-collapse
  > .list-group:first-child
  .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel
  > .panel-heading
  + .panel-collapse
  > .list-group
  .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}

.vsx-panel-default > .panel-heading {
  color: var(--color-text-default);
  background-color: #f5f5f5;
  border-color: #c8ccce;
}

.panel-body:before,
.panel-body:after {
  display: table;
  content: ' ';
}

.panel-body:after {
  clear: both;
}
/* END BOOTSTRAP STYLES */

.cj-panel.panel-heading {
  background-color: var(--color-background-default);
}

.cj-panel.panel-footer {
  background-color: var(--color-background-default);
}

.cj-panel.panel-heading legend {
  text-transform: uppercase;
  color: #bbb;
  font-weight: 500;
  font-size: 1.6rem;
  border: none;
  padding-top: 0px;
  margin-bottom: 0px;
}

.cj-panel.panel .cj-panel.panel-body:not(:last-child) {
  border-bottom: 1px solid transparent;
  border-color: #c8ccce;
}
.cj-panel-body-padding-wide.panel-body {
  padding: 32px;
}

.cj-panel-body-padding-none.panel-body {
  padding: 0;
}

.vsx-top-nav-layout {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
}
.vsx-top-nav-layout.vsx-top-nav-layout-absolute {
  position: absolute;
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav {
  color: white;
  box-sizing: border-box;
  background-color: var(--color-background-dark-2);
  flex-basis: 64px;
  flex-shrink: 0;
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-actions {
  fill: #bebfc0;
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-actions .vsx-top-nav-layout-cancel-action {
  color: #fff;
  opacity: 0.64;
  background: hsla(206deg, 3%, 45%, 0.1);
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-actions .vsx-top-nav-layout-cancel-action:hover {
  opacity: 1;
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-logo {
  width: 32px;
  height: 32px;
  fill: white;
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-title {
  font-size: 20px;
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-action {
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  color: #fff;
  opacity: 0.64;
  background: hsla(206deg, 3%, 45%, 0.1);
}
.vsx-top-nav-layout .vsx-top-nav-layout-topnav .vsx-top-nav-layout-action:hover {
  opacity: 1;
}
.vsx-top-nav-layout .vsx-top-nav-layout-content {
  box-sizing: border-box;
  padding: 24px;
  background-color: var(--color-background-light-2);
  width: 100%;
  flex-grow: 1;
  overflow: scroll;
}
.vsx-cj-logo {
  fill: #151c23;
}

.vsx-cj-logo-padded {
  padding: 4px;
}

.vsx-cj-logo-small {
  width: 24px;
  height: 24px;
}

.vsx-cj-logo-medium {
  width: 32px;
  height: 32px;
}

.vsx-cj-logo-large {
  width: 60px;
  height: 60px;
}
/*!
 * BASED ON
 * Bootstrap Grid v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.vsx-container,
.vsx-container-fluid,
.vsx-container-xxl,
.vsx-container-xl,
.vsx-container-lg,
.vsx-container-md,
.vsx-container-sm {
  --vsx-gutter-x: 1.5rem;
  --vsx-gutter-y: 0;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .vsx-container-sm,
.vsx-container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .vsx-container-md,
.vsx-container-sm,
.vsx-container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .vsx-container-lg,
.vsx-container-md,
.vsx-container-sm,
.vsx-container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .vsx-container-xl,
.vsx-container-lg,
.vsx-container-md,
.vsx-container-sm,
.vsx-container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .vsx-container-xxl,
.vsx-container-xl,
.vsx-container-lg,
.vsx-container-md,
.vsx-container-sm,
.vsx-container {
    max-width: 1320px;
  }
}
.line-break {
  width: 100%;
}

.vsx-row {
  --vsx-gutter-x: 1.5rem;
  --vsx-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--vsx-gutter-y));
}

.vsx-row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--vsx-gutter-x) * 0.5);
  padding-left: calc(var(--vsx-gutter-x) * 0.5);
  margin-top: var(--vsx-gutter-y);
}

.vsx-col {
  flex: 1 0;
}

.vsx-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.vsx-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.vsx-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.vsx-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.vsx-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.vsx-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.vsx-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.vsx-col-auto {
  flex: 0 0 auto;
  width: auto;
}

.vsx-col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.vsx-col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.vsx-col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.vsx-col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.vsx-col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.vsx-col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.vsx-col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.vsx-col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.vsx-col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.vsx-col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.vsx-col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.vsx-col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.vsx-offset-1 {
  margin-left: 8.33333333%;
}

.vsx-offset-2 {
  margin-left: 16.66666667%;
}

.vsx-offset-3 {
  margin-left: 25%;
}

.vsx-offset-4 {
  margin-left: 33.33333333%;
}

.vsx-offset-5 {
  margin-left: 41.66666667%;
}

.vsx-offset-6 {
  margin-left: 50%;
}

.vsx-offset-7 {
  margin-left: 58.33333333%;
}

.vsx-offset-8 {
  margin-left: 66.66666667%;
}

.vsx-offset-9 {
  margin-left: 75%;
}

.vsx-offset-10 {
  margin-left: 83.33333333%;
}

.vsx-offset-11 {
  margin-left: 91.66666667%;
}

.vsx-g-0,
.vsx-gx-0 {
  --vsx-gutter-x: 0;
}

.vsx-g-0,
.vsx-gy-0 {
  --vsx-gutter-y: 0;
}

.vsx-g-1,
.vsx-gx-1 {
  --vsx-gutter-x: 0.25rem;
}

.vsx-g-1,
.vsx-gy-1 {
  --vsx-gutter-y: 0.25rem;
}

.vsx-g-2,
.vsx-gx-2 {
  --vsx-gutter-x: 0.5rem;
}

.vsx-g-2,
.vsx-gy-2 {
  --vsx-gutter-y: 0.5rem;
}

.vsx-g-3,
.vsx-gx-3 {
  --vsx-gutter-x: 1rem;
}

.vsx-g-3,
.vsx-gy-3 {
  --vsx-gutter-y: 1rem;
}

.vsx-g-4,
.vsx-gx-4 {
  --vsx-gutter-x: 1.5rem;
}

.vsx-g-4,
.vsx-gy-4 {
  --vsx-gutter-y: 1.5rem;
}

.vsx-g-5,
.vsx-gx-5 {
  --vsx-gutter-x: 3rem;
}

.vsx-g-5,
.vsx-gy-5 {
  --vsx-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .vsx-col-sm {
    flex: 1 0;
  }
  .vsx-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .vsx-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .vsx-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .vsx-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .vsx-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .vsx-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .vsx-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .vsx-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .vsx-col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .vsx-col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .vsx-col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .vsx-col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .vsx-col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .vsx-col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .vsx-col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .vsx-col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .vsx-col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .vsx-col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .vsx-col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .vsx-col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .vsx-offset-sm-0 {
    margin-left: 0;
  }
  .vsx-offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .vsx-offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .vsx-offset-sm-3 {
    margin-left: 25%;
  }
  .vsx-offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .vsx-offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .vsx-offset-sm-6 {
    margin-left: 50%;
  }
  .vsx-offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .vsx-offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .vsx-offset-sm-9 {
    margin-left: 75%;
  }
  .vsx-offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .vsx-offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .vsx-g-sm-0,
.vsx-gx-sm-0 {
    --vsx-gutter-x: 0;
  }
  .vsx-g-sm-0,
.vsx-gy-sm-0 {
    --vsx-gutter-y: 0;
  }
  .vsx-g-sm-1,
.vsx-gx-sm-1 {
    --vsx-gutter-x: 0.25rem;
  }
  .vsx-g-sm-1,
.vsx-gy-sm-1 {
    --vsx-gutter-y: 0.25rem;
  }
  .vsx-g-sm-2,
.vsx-gx-sm-2 {
    --vsx-gutter-x: 0.5rem;
  }
  .vsx-g-sm-2,
.vsx-gy-sm-2 {
    --vsx-gutter-y: 0.5rem;
  }
  .vsx-g-sm-3,
.vsx-gx-sm-3 {
    --vsx-gutter-x: 1rem;
  }
  .vsx-g-sm-3,
.vsx-gy-sm-3 {
    --vsx-gutter-y: 1rem;
  }
  .vsx-g-sm-4,
.vsx-gx-sm-4 {
    --vsx-gutter-x: 1.5rem;
  }
  .vsx-g-sm-4,
.vsx-gy-sm-4 {
    --vsx-gutter-y: 1.5rem;
  }
  .vsx-g-sm-5,
.vsx-gx-sm-5 {
    --vsx-gutter-x: 3rem;
  }
  .vsx-g-sm-5,
.vsx-gy-sm-5 {
    --vsx-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .vsx-col-md {
    flex: 1 0;
  }
  .vsx-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .vsx-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .vsx-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .vsx-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .vsx-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .vsx-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .vsx-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .vsx-col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .vsx-col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .vsx-col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .vsx-col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .vsx-col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .vsx-col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .vsx-col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .vsx-col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .vsx-col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .vsx-col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .vsx-col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .vsx-col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .vsx-col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .vsx-offset-md-0 {
    margin-left: 0;
  }
  .vsx-offset-md-1 {
    margin-left: 8.33333333%;
  }
  .vsx-offset-md-2 {
    margin-left: 16.66666667%;
  }
  .vsx-offset-md-3 {
    margin-left: 25%;
  }
  .vsx-offset-md-4 {
    margin-left: 33.33333333%;
  }
  .vsx-offset-md-5 {
    margin-left: 41.66666667%;
  }
  .vsx-offset-md-6 {
    margin-left: 50%;
  }
  .vsx-offset-md-7 {
    margin-left: 58.33333333%;
  }
  .vsx-offset-md-8 {
    margin-left: 66.66666667%;
  }
  .vsx-offset-md-9 {
    margin-left: 75%;
  }
  .vsx-offset-md-10 {
    margin-left: 83.33333333%;
  }
  .vsx-offset-md-11 {
    margin-left: 91.66666667%;
  }
  .vsx-g-md-0,
.vsx-gx-md-0 {
    --vsx-gutter-x: 0;
  }
  .vsx-g-md-0,
.vsx-gy-md-0 {
    --vsx-gutter-y: 0;
  }
  .vsx-g-md-1,
.vsx-gx-md-1 {
    --vsx-gutter-x: 0.25rem;
  }
  .vsx-g-md-1,
.vsx-gy-md-1 {
    --vsx-gutter-y: 0.25rem;
  }
  .vsx-g-md-2,
.vsx-gx-md-2 {
    --vsx-gutter-x: 0.5rem;
  }
  .vsx-g-md-2,
.vsx-gy-md-2 {
    --vsx-gutter-y: 0.5rem;
  }
  .vsx-g-md-3,
.vsx-gx-md-3 {
    --vsx-gutter-x: 1rem;
  }
  .vsx-g-md-3,
.vsx-gy-md-3 {
    --vsx-gutter-y: 1rem;
  }
  .vsx-g-md-4,
.vsx-gx-md-4 {
    --vsx-gutter-x: 1.5rem;
  }
  .vsx-g-md-4,
.vsx-gy-md-4 {
    --vsx-gutter-y: 1.5rem;
  }
  .vsx-g-md-5,
.vsx-gx-md-5 {
    --vsx-gutter-x: 3rem;
  }
  .vsx-g-md-5,
.vsx-gy-md-5 {
    --vsx-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .vsx-col-lg {
    flex: 1 0;
  }
  .vsx-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .vsx-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .vsx-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .vsx-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .vsx-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .vsx-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .vsx-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .vsx-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .vsx-col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .vsx-col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .vsx-col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .vsx-col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .vsx-col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .vsx-col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .vsx-col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .vsx-col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .vsx-col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .vsx-col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .vsx-col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .vsx-col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .vsx-offset-lg-0 {
    margin-left: 0;
  }
  .vsx-offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .vsx-offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .vsx-offset-lg-3 {
    margin-left: 25%;
  }
  .vsx-offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .vsx-offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .vsx-offset-lg-6 {
    margin-left: 50%;
  }
  .vsx-offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .vsx-offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .vsx-offset-lg-9 {
    margin-left: 75%;
  }
  .vsx-offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .vsx-offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .vsx-g-lg-0,
.vsx-gx-lg-0 {
    --vsx-gutter-x: 0;
  }
  .vsx-g-lg-0,
.vsx-gy-lg-0 {
    --vsx-gutter-y: 0;
  }
  .vsx-g-lg-1,
.vsx-gx-lg-1 {
    --vsx-gutter-x: 0.25rem;
  }
  .vsx-g-lg-1,
.vsx-gy-lg-1 {
    --vsx-gutter-y: 0.25rem;
  }
  .vsx-g-lg-2,
.vsx-gx-lg-2 {
    --vsx-gutter-x: 0.5rem;
  }
  .vsx-g-lg-2,
.vsx-gy-lg-2 {
    --vsx-gutter-y: 0.5rem;
  }
  .vsx-g-lg-3,
.vsx-gx-lg-3 {
    --vsx-gutter-x: 1rem;
  }
  .vsx-g-lg-3,
.vsx-gy-lg-3 {
    --vsx-gutter-y: 1rem;
  }
  .vsx-g-lg-4,
.vsx-gx-lg-4 {
    --vsx-gutter-x: 1.5rem;
  }
  .vsx-g-lg-4,
.vsx-gy-lg-4 {
    --vsx-gutter-y: 1.5rem;
  }
  .vsx-g-lg-5,
.vsx-gx-lg-5 {
    --vsx-gutter-x: 3rem;
  }
  .vsx-g-lg-5,
.vsx-gy-lg-5 {
    --vsx-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .vsx-col-xl {
    flex: 1 0;
  }
  .vsx-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .vsx-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .vsx-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .vsx-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .vsx-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .vsx-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .vsx-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .vsx-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .vsx-col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .vsx-col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .vsx-col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .vsx-col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .vsx-col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .vsx-col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .vsx-col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .vsx-col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .vsx-col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .vsx-col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .vsx-col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .vsx-col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .vsx-offset-xl-0 {
    margin-left: 0;
  }
  .vsx-offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .vsx-offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .vsx-offset-xl-3 {
    margin-left: 25%;
  }
  .vsx-offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .vsx-offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .vsx-offset-xl-6 {
    margin-left: 50%;
  }
  .vsx-offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .vsx-offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .vsx-offset-xl-9 {
    margin-left: 75%;
  }
  .vsx-offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .vsx-offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .vsx-g-xl-0,
.vsx-gx-xl-0 {
    --vsx-gutter-x: 0;
  }
  .vsx-g-xl-0,
.vsx-gy-xl-0 {
    --vsx-gutter-y: 0;
  }
  .vsx-g-xl-1,
.vsx-gx-xl-1 {
    --vsx-gutter-x: 0.25rem;
  }
  .vsx-g-xl-1,
.vsx-gy-xl-1 {
    --vsx-gutter-y: 0.25rem;
  }
  .vsx-g-xl-2,
.vsx-gx-xl-2 {
    --vsx-gutter-x: 0.5rem;
  }
  .vsx-g-xl-2,
.vsx-gy-xl-2 {
    --vsx-gutter-y: 0.5rem;
  }
  .vsx-g-xl-3,
.vsx-gx-xl-3 {
    --vsx-gutter-x: 1rem;
  }
  .vsx-g-xl-3,
.vsx-gy-xl-3 {
    --vsx-gutter-y: 1rem;
  }
  .vsx-g-xl-4,
.vsx-gx-xl-4 {
    --vsx-gutter-x: 1.5rem;
  }
  .vsx-g-xl-4,
.vsx-gy-xl-4 {
    --vsx-gutter-y: 1.5rem;
  }
  .vsx-g-xl-5,
.vsx-gx-xl-5 {
    --vsx-gutter-x: 3rem;
  }
  .vsx-g-xl-5,
.vsx-gy-xl-5 {
    --vsx-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .vsx-col-xxl {
    flex: 1 0;
  }
  .vsx-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .vsx-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .vsx-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .vsx-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .vsx-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .vsx-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .vsx-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .vsx-col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .vsx-col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .vsx-col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .vsx-col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .vsx-col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .vsx-col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .vsx-col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .vsx-col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .vsx-col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .vsx-col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .vsx-col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .vsx-col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .vsx-col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .vsx-offset-xxl-0 {
    margin-left: 0;
  }
  .vsx-offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .vsx-offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .vsx-offset-xxl-3 {
    margin-left: 25%;
  }
  .vsx-offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .vsx-offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .vsx-offset-xxl-6 {
    margin-left: 50%;
  }
  .vsx-offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .vsx-offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .vsx-offset-xxl-9 {
    margin-left: 75%;
  }
  .vsx-offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .vsx-offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .vsx-g-xxl-0,
.vsx-gx-xxl-0 {
    --vsx-gutter-x: 0;
  }
  .vsx-g-xxl-0,
.vsx-gy-xxl-0 {
    --vsx-gutter-y: 0;
  }
  .vsx-g-xxl-1,
.vsx-gx-xxl-1 {
    --vsx-gutter-x: 0.25rem;
  }
  .vsx-g-xxl-1,
.vsx-gy-xxl-1 {
    --vsx-gutter-y: 0.25rem;
  }
  .vsx-g-xxl-2,
.vsx-gx-xxl-2 {
    --vsx-gutter-x: 0.5rem;
  }
  .vsx-g-xxl-2,
.vsx-gy-xxl-2 {
    --vsx-gutter-y: 0.5rem;
  }
  .vsx-g-xxl-3,
.vsx-gx-xxl-3 {
    --vsx-gutter-x: 1rem;
  }
  .vsx-g-xxl-3,
.vsx-gy-xxl-3 {
    --vsx-gutter-y: 1rem;
  }
  .vsx-g-xxl-4,
.vsx-gx-xxl-4 {
    --vsx-gutter-x: 1.5rem;
  }
  .vsx-g-xxl-4,
.vsx-gy-xxl-4 {
    --vsx-gutter-y: 1.5rem;
  }
  .vsx-g-xxl-5,
.vsx-gx-xxl-5 {
    --vsx-gutter-x: 3rem;
  }
  .vsx-g-xxl-5,
.vsx-gy-xxl-5 {
    --vsx-gutter-y: 3rem;
  }
}
.vsx-d-inline {
  display: inline !important;
}

.vsx-d-inline-block {
  display: inline-block !important;
}

.vsx-d-block {
  display: block !important;
}

.vsx-d-grid {
  display: grid !important;
}

.vsx-d-table {
  display: table !important;
}

.vsx-d-table-row {
  display: table-row !important;
}

.vsx-d-table-cell {
  display: table-cell !important;
}

.vsx-d-flex {
  display: flex !important;
}

.vsx-d-inline-flex {
  display: inline-flex !important;
}

.vsx-d-none {
  display: none !important;
}

.vsx-flex-fill {
  flex: 1 1 auto !important;
}

.vsx-flex-row {
  flex-direction: row !important;
}

.vsx-flex-column {
  flex-direction: column !important;
}

.vsx-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.vsx-flex-column-reverse {
  flex-direction: column-reverse !important;
}

.vsx-flex-grow-0 {
  flex-grow: 0 !important;
}

.vsx-flex-grow-1 {
  flex-grow: 1 !important;
}

.vsx-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.vsx-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.vsx-flex-wrap {
  flex-wrap: wrap !important;
}

.vsx-flex-nowrap {
  flex-wrap: nowrap !important;
}

.vsx-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.vsx-justify-content-start {
  justify-content: flex-start !important;
}

.vsx-justify-content-end {
  justify-content: flex-end !important;
}

.vsx-justify-content-center {
  justify-content: center !important;
}

.vsx-justify-content-between {
  justify-content: space-between !important;
}

.vsx-justify-content-around {
  justify-content: space-around !important;
}

.vsx-justify-content-evenly {
  justify-content: space-evenly !important;
}

.vsx-align-items-start {
  align-items: flex-start !important;
}

.vsx-align-items-end {
  align-items: flex-end !important;
}

.vsx-align-items-center {
  align-items: center !important;
}

.vsx-align-items-baseline {
  align-items: baseline !important;
}

.vsx-align-items-stretch {
  align-items: stretch !important;
}

.vsx-align-content-start {
  align-content: flex-start !important;
}

.vsx-align-content-end {
  align-content: flex-end !important;
}

.vsx-align-content-center {
  align-content: center !important;
}

.vsx-align-content-between {
  align-content: space-between !important;
}

.vsx-align-content-around {
  align-content: space-around !important;
}

.vsx-align-content-stretch {
  align-content: stretch !important;
}

.vsx-align-self-auto {
  align-self: auto !important;
}

.vsx-align-self-start {
  align-self: flex-start !important;
}

.vsx-align-self-end {
  align-self: flex-end !important;
}

.vsx-align-self-center {
  align-self: center !important;
}

.vsx-align-self-baseline {
  align-self: baseline !important;
}

.vsx-align-self-stretch {
  align-self: stretch !important;
}

.vsx-order-first {
  order: -1 !important;
}

.vsx-order-0 {
  order: 0 !important;
}

.vsx-order-1 {
  order: 1 !important;
}

.vsx-order-2 {
  order: 2 !important;
}

.vsx-order-3 {
  order: 3 !important;
}

.vsx-order-4 {
  order: 4 !important;
}

.vsx-order-5 {
  order: 5 !important;
}

.vsx-order-last {
  order: 6 !important;
}

.vsx-m-0 {
  margin: 0 !important;
}

.vsx-m-1 {
  margin: 0.25rem !important;
}

.vsx-m-2 {
  margin: 0.5rem !important;
}

.vsx-m-3 {
  margin: 1rem !important;
}

.vsx-m-4 {
  margin: 1.5rem !important;
}

.vsx-m-5 {
  margin: 3rem !important;
}

.vsx-m-auto {
  margin: auto !important;
}

.vsx-mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.vsx-mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.vsx-mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.vsx-mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.vsx-mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.vsx-mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.vsx-mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.vsx-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.vsx-my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.vsx-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.vsx-my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.vsx-my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.vsx-my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.vsx-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.vsx-mt-0 {
  margin-top: 0 !important;
}

.vsx-mt-1 {
  margin-top: 0.25rem !important;
}

.vsx-mt-2 {
  margin-top: 0.5rem !important;
}

.vsx-mt-3 {
  margin-top: 1rem !important;
}

.vsx-mt-4 {
  margin-top: 1.5rem !important;
}

.vsx-mt-5 {
  margin-top: 3rem !important;
}

.vsx-mt-auto {
  margin-top: auto !important;
}

.vsx-me-0 {
  margin-right: 0 !important;
}

.vsx-me-1 {
  margin-right: 0.25rem !important;
}

.vsx-me-2 {
  margin-right: 0.5rem !important;
}

.vsx-me-3 {
  margin-right: 1rem !important;
}

.vsx-me-4 {
  margin-right: 1.5rem !important;
}

.vsx-me-5 {
  margin-right: 3rem !important;
}

.vsx-me-auto {
  margin-right: auto !important;
}

.vsx-mb-0 {
  margin-bottom: 0 !important;
}

.vsx-mb-1 {
  margin-bottom: 0.25rem !important;
}

.vsx-mb-2 {
  margin-bottom: 0.5rem !important;
}

.vsx-mb-3 {
  margin-bottom: 1rem !important;
}

.vsx-mb-4 {
  margin-bottom: 1.5rem !important;
}

.vsx-mb-5 {
  margin-bottom: 3rem !important;
}

.vsx-mb-auto {
  margin-bottom: auto !important;
}

.vsx-ms-0 {
  margin-left: 0 !important;
}

.vsx-ms-1 {
  margin-left: 0.25rem !important;
}

.vsx-ms-2 {
  margin-left: 0.5rem !important;
}

.vsx-ms-3 {
  margin-left: 1rem !important;
}

.vsx-ms-4 {
  margin-left: 1.5rem !important;
}

.vsx-ms-5 {
  margin-left: 3rem !important;
}

.vsx-ms-auto {
  margin-left: auto !important;
}

.vsx-p-0 {
  padding: 0 !important;
}

.vsx-p-1 {
  padding: 0.25rem !important;
}

.vsx-p-2 {
  padding: 0.5rem !important;
}

.vsx-p-3 {
  padding: 1rem !important;
}

.vsx-p-4 {
  padding: 1.5rem !important;
}

.vsx-p-5 {
  padding: 3rem !important;
}

.vsx-px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.vsx-px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.vsx-px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.vsx-px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.vsx-px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.vsx-px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.vsx-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.vsx-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.vsx-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.vsx-py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.vsx-py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.vsx-py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.vsx-pt-0 {
  padding-top: 0 !important;
}

.vsx-pt-1 {
  padding-top: 0.25rem !important;
}

.vsx-pt-2 {
  padding-top: 0.5rem !important;
}

.vsx-pt-3 {
  padding-top: 1rem !important;
}

.vsx-pt-4 {
  padding-top: 1.5rem !important;
}

.vsx-pt-5 {
  padding-top: 3rem !important;
}

.vsx-pe-0 {
  padding-right: 0 !important;
}

.vsx-pe-1 {
  padding-right: 0.25rem !important;
}

.vsx-pe-2 {
  padding-right: 0.5rem !important;
}

.vsx-pe-3 {
  padding-right: 1rem !important;
}

.vsx-pe-4 {
  padding-right: 1.5rem !important;
}

.vsx-pe-5 {
  padding-right: 3rem !important;
}

.vsx-pb-0 {
  padding-bottom: 0 !important;
}

.vsx-pb-1 {
  padding-bottom: 0.25rem !important;
}

.vsx-pb-2 {
  padding-bottom: 0.5rem !important;
}

.vsx-pb-3 {
  padding-bottom: 1rem !important;
}

.vsx-pb-4 {
  padding-bottom: 1.5rem !important;
}

.vsx-pb-5 {
  padding-bottom: 3rem !important;
}

.vsx-ps-0 {
  padding-left: 0 !important;
}

.vsx-ps-1 {
  padding-left: 0.25rem !important;
}

.vsx-ps-2 {
  padding-left: 0.5rem !important;
}

.vsx-ps-3 {
  padding-left: 1rem !important;
}

.vsx-ps-4 {
  padding-left: 1.5rem !important;
}

.vsx-ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 576px) {
  .vsx-d-sm-inline {
    display: inline !important;
  }
  .vsx-d-sm-inline-block {
    display: inline-block !important;
  }
  .vsx-d-sm-block {
    display: block !important;
  }
  .vsx-d-sm-grid {
    display: grid !important;
  }
  .vsx-d-sm-table {
    display: table !important;
  }
  .vsx-d-sm-table-row {
    display: table-row !important;
  }
  .vsx-d-sm-table-cell {
    display: table-cell !important;
  }
  .vsx-d-sm-flex {
    display: flex !important;
  }
  .vsx-d-sm-inline-flex {
    display: inline-flex !important;
  }
  .vsx-d-sm-none {
    display: none !important;
  }
  .vsx-flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .vsx-flex-sm-row {
    flex-direction: row !important;
  }
  .vsx-flex-sm-column {
    flex-direction: column !important;
  }
  .vsx-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .vsx-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .vsx-flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .vsx-flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .vsx-flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .vsx-flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .vsx-flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .vsx-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .vsx-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .vsx-justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .vsx-justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .vsx-justify-content-sm-center {
    justify-content: center !important;
  }
  .vsx-justify-content-sm-between {
    justify-content: space-between !important;
  }
  .vsx-justify-content-sm-around {
    justify-content: space-around !important;
  }
  .vsx-justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .vsx-align-items-sm-start {
    align-items: flex-start !important;
  }
  .vsx-align-items-sm-end {
    align-items: flex-end !important;
  }
  .vsx-align-items-sm-center {
    align-items: center !important;
  }
  .vsx-align-items-sm-baseline {
    align-items: baseline !important;
  }
  .vsx-align-items-sm-stretch {
    align-items: stretch !important;
  }
  .vsx-align-content-sm-start {
    align-content: flex-start !important;
  }
  .vsx-align-content-sm-end {
    align-content: flex-end !important;
  }
  .vsx-align-content-sm-center {
    align-content: center !important;
  }
  .vsx-align-content-sm-between {
    align-content: space-between !important;
  }
  .vsx-align-content-sm-around {
    align-content: space-around !important;
  }
  .vsx-align-content-sm-stretch {
    align-content: stretch !important;
  }
  .vsx-align-self-sm-auto {
    align-self: auto !important;
  }
  .vsx-align-self-sm-start {
    align-self: flex-start !important;
  }
  .vsx-align-self-sm-end {
    align-self: flex-end !important;
  }
  .vsx-align-self-sm-center {
    align-self: center !important;
  }
  .vsx-align-self-sm-baseline {
    align-self: baseline !important;
  }
  .vsx-align-self-sm-stretch {
    align-self: stretch !important;
  }
  .vsx-order-sm-first {
    order: -1 !important;
  }
  .vsx-order-sm-0 {
    order: 0 !important;
  }
  .vsx-order-sm-1 {
    order: 1 !important;
  }
  .vsx-order-sm-2 {
    order: 2 !important;
  }
  .vsx-order-sm-3 {
    order: 3 !important;
  }
  .vsx-order-sm-4 {
    order: 4 !important;
  }
  .vsx-order-sm-5 {
    order: 5 !important;
  }
  .vsx-order-sm-last {
    order: 6 !important;
  }
  .vsx-m-sm-0 {
    margin: 0 !important;
  }
  .vsx-m-sm-1 {
    margin: 0.25rem !important;
  }
  .vsx-m-sm-2 {
    margin: 0.5rem !important;
  }
  .vsx-m-sm-3 {
    margin: 1rem !important;
  }
  .vsx-m-sm-4 {
    margin: 1.5rem !important;
  }
  .vsx-m-sm-5 {
    margin: 3rem !important;
  }
  .vsx-m-sm-auto {
    margin: auto !important;
  }
  .vsx-mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .vsx-mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .vsx-mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .vsx-mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .vsx-mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .vsx-mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .vsx-mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .vsx-my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .vsx-my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .vsx-my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .vsx-my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .vsx-my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .vsx-my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .vsx-my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .vsx-mt-sm-0 {
    margin-top: 0 !important;
  }
  .vsx-mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .vsx-mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .vsx-mt-sm-3 {
    margin-top: 1rem !important;
  }
  .vsx-mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .vsx-mt-sm-5 {
    margin-top: 3rem !important;
  }
  .vsx-mt-sm-auto {
    margin-top: auto !important;
  }
  .vsx-me-sm-0 {
    margin-right: 0 !important;
  }
  .vsx-me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .vsx-me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .vsx-me-sm-3 {
    margin-right: 1rem !important;
  }
  .vsx-me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .vsx-me-sm-5 {
    margin-right: 3rem !important;
  }
  .vsx-me-sm-auto {
    margin-right: auto !important;
  }
  .vsx-mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .vsx-mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .vsx-mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .vsx-mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .vsx-mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .vsx-mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .vsx-mb-sm-auto {
    margin-bottom: auto !important;
  }
  .vsx-ms-sm-0 {
    margin-left: 0 !important;
  }
  .vsx-ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .vsx-ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .vsx-ms-sm-3 {
    margin-left: 1rem !important;
  }
  .vsx-ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .vsx-ms-sm-5 {
    margin-left: 3rem !important;
  }
  .vsx-ms-sm-auto {
    margin-left: auto !important;
  }
  .vsx-p-sm-0 {
    padding: 0 !important;
  }
  .vsx-p-sm-1 {
    padding: 0.25rem !important;
  }
  .vsx-p-sm-2 {
    padding: 0.5rem !important;
  }
  .vsx-p-sm-3 {
    padding: 1rem !important;
  }
  .vsx-p-sm-4 {
    padding: 1.5rem !important;
  }
  .vsx-p-sm-5 {
    padding: 3rem !important;
  }
  .vsx-px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .vsx-px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .vsx-px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .vsx-px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .vsx-px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .vsx-px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .vsx-py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .vsx-py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .vsx-py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .vsx-py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .vsx-py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .vsx-py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .vsx-pt-sm-0 {
    padding-top: 0 !important;
  }
  .vsx-pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .vsx-pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .vsx-pt-sm-3 {
    padding-top: 1rem !important;
  }
  .vsx-pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .vsx-pt-sm-5 {
    padding-top: 3rem !important;
  }
  .vsx-pe-sm-0 {
    padding-right: 0 !important;
  }
  .vsx-pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .vsx-pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .vsx-pe-sm-3 {
    padding-right: 1rem !important;
  }
  .vsx-pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .vsx-pe-sm-5 {
    padding-right: 3rem !important;
  }
  .vsx-pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .vsx-pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .vsx-pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .vsx-pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .vsx-pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .vsx-pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .vsx-ps-sm-0 {
    padding-left: 0 !important;
  }
  .vsx-ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .vsx-ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .vsx-ps-sm-3 {
    padding-left: 1rem !important;
  }
  .vsx-ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .vsx-ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .vsx-d-md-inline {
    display: inline !important;
  }
  .vsx-d-md-inline-block {
    display: inline-block !important;
  }
  .vsx-d-md-block {
    display: block !important;
  }
  .vsx-d-md-grid {
    display: grid !important;
  }
  .vsx-d-md-table {
    display: table !important;
  }
  .vsx-d-md-table-row {
    display: table-row !important;
  }
  .vsx-d-md-table-cell {
    display: table-cell !important;
  }
  .vsx-d-md-flex {
    display: flex !important;
  }
  .vsx-d-md-inline-flex {
    display: inline-flex !important;
  }
  .vsx-d-md-none {
    display: none !important;
  }
  .vsx-flex-md-fill {
    flex: 1 1 auto !important;
  }
  .vsx-flex-md-row {
    flex-direction: row !important;
  }
  .vsx-flex-md-column {
    flex-direction: column !important;
  }
  .vsx-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .vsx-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .vsx-flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .vsx-flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .vsx-flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .vsx-flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .vsx-flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .vsx-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .vsx-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .vsx-justify-content-md-start {
    justify-content: flex-start !important;
  }
  .vsx-justify-content-md-end {
    justify-content: flex-end !important;
  }
  .vsx-justify-content-md-center {
    justify-content: center !important;
  }
  .vsx-justify-content-md-between {
    justify-content: space-between !important;
  }
  .vsx-justify-content-md-around {
    justify-content: space-around !important;
  }
  .vsx-justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .vsx-align-items-md-start {
    align-items: flex-start !important;
  }
  .vsx-align-items-md-end {
    align-items: flex-end !important;
  }
  .vsx-align-items-md-center {
    align-items: center !important;
  }
  .vsx-align-items-md-baseline {
    align-items: baseline !important;
  }
  .vsx-align-items-md-stretch {
    align-items: stretch !important;
  }
  .vsx-align-content-md-start {
    align-content: flex-start !important;
  }
  .vsx-align-content-md-end {
    align-content: flex-end !important;
  }
  .vsx-align-content-md-center {
    align-content: center !important;
  }
  .vsx-align-content-md-between {
    align-content: space-between !important;
  }
  .vsx-align-content-md-around {
    align-content: space-around !important;
  }
  .vsx-align-content-md-stretch {
    align-content: stretch !important;
  }
  .vsx-align-self-md-auto {
    align-self: auto !important;
  }
  .vsx-align-self-md-start {
    align-self: flex-start !important;
  }
  .vsx-align-self-md-end {
    align-self: flex-end !important;
  }
  .vsx-align-self-md-center {
    align-self: center !important;
  }
  .vsx-align-self-md-baseline {
    align-self: baseline !important;
  }
  .vsx-align-self-md-stretch {
    align-self: stretch !important;
  }
  .vsx-order-md-first {
    order: -1 !important;
  }
  .vsx-order-md-0 {
    order: 0 !important;
  }
  .vsx-order-md-1 {
    order: 1 !important;
  }
  .vsx-order-md-2 {
    order: 2 !important;
  }
  .vsx-order-md-3 {
    order: 3 !important;
  }
  .vsx-order-md-4 {
    order: 4 !important;
  }
  .vsx-order-md-5 {
    order: 5 !important;
  }
  .vsx-order-md-last {
    order: 6 !important;
  }
  .vsx-m-md-0 {
    margin: 0 !important;
  }
  .vsx-m-md-1 {
    margin: 0.25rem !important;
  }
  .vsx-m-md-2 {
    margin: 0.5rem !important;
  }
  .vsx-m-md-3 {
    margin: 1rem !important;
  }
  .vsx-m-md-4 {
    margin: 1.5rem !important;
  }
  .vsx-m-md-5 {
    margin: 3rem !important;
  }
  .vsx-m-md-auto {
    margin: auto !important;
  }
  .vsx-mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .vsx-mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .vsx-mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .vsx-mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .vsx-mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .vsx-mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .vsx-mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .vsx-my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .vsx-my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .vsx-my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .vsx-my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .vsx-my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .vsx-my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .vsx-my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .vsx-mt-md-0 {
    margin-top: 0 !important;
  }
  .vsx-mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .vsx-mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .vsx-mt-md-3 {
    margin-top: 1rem !important;
  }
  .vsx-mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .vsx-mt-md-5 {
    margin-top: 3rem !important;
  }
  .vsx-mt-md-auto {
    margin-top: auto !important;
  }
  .vsx-me-md-0 {
    margin-right: 0 !important;
  }
  .vsx-me-md-1 {
    margin-right: 0.25rem !important;
  }
  .vsx-me-md-2 {
    margin-right: 0.5rem !important;
  }
  .vsx-me-md-3 {
    margin-right: 1rem !important;
  }
  .vsx-me-md-4 {
    margin-right: 1.5rem !important;
  }
  .vsx-me-md-5 {
    margin-right: 3rem !important;
  }
  .vsx-me-md-auto {
    margin-right: auto !important;
  }
  .vsx-mb-md-0 {
    margin-bottom: 0 !important;
  }
  .vsx-mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .vsx-mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .vsx-mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .vsx-mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .vsx-mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .vsx-mb-md-auto {
    margin-bottom: auto !important;
  }
  .vsx-ms-md-0 {
    margin-left: 0 !important;
  }
  .vsx-ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .vsx-ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .vsx-ms-md-3 {
    margin-left: 1rem !important;
  }
  .vsx-ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .vsx-ms-md-5 {
    margin-left: 3rem !important;
  }
  .vsx-ms-md-auto {
    margin-left: auto !important;
  }
  .vsx-p-md-0 {
    padding: 0 !important;
  }
  .vsx-p-md-1 {
    padding: 0.25rem !important;
  }
  .vsx-p-md-2 {
    padding: 0.5rem !important;
  }
  .vsx-p-md-3 {
    padding: 1rem !important;
  }
  .vsx-p-md-4 {
    padding: 1.5rem !important;
  }
  .vsx-p-md-5 {
    padding: 3rem !important;
  }
  .vsx-px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .vsx-px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .vsx-px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .vsx-px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .vsx-px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .vsx-px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .vsx-py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .vsx-py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .vsx-py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .vsx-py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .vsx-py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .vsx-py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .vsx-pt-md-0 {
    padding-top: 0 !important;
  }
  .vsx-pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .vsx-pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .vsx-pt-md-3 {
    padding-top: 1rem !important;
  }
  .vsx-pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .vsx-pt-md-5 {
    padding-top: 3rem !important;
  }
  .vsx-pe-md-0 {
    padding-right: 0 !important;
  }
  .vsx-pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .vsx-pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .vsx-pe-md-3 {
    padding-right: 1rem !important;
  }
  .vsx-pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .vsx-pe-md-5 {
    padding-right: 3rem !important;
  }
  .vsx-pb-md-0 {
    padding-bottom: 0 !important;
  }
  .vsx-pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .vsx-pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .vsx-pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .vsx-pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .vsx-pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .vsx-ps-md-0 {
    padding-left: 0 !important;
  }
  .vsx-ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .vsx-ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .vsx-ps-md-3 {
    padding-left: 1rem !important;
  }
  .vsx-ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .vsx-ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .vsx-d-lg-inline {
    display: inline !important;
  }
  .vsx-d-lg-inline-block {
    display: inline-block !important;
  }
  .vsx-d-lg-block {
    display: block !important;
  }
  .vsx-d-lg-grid {
    display: grid !important;
  }
  .vsx-d-lg-table {
    display: table !important;
  }
  .vsx-d-lg-table-row {
    display: table-row !important;
  }
  .vsx-d-lg-table-cell {
    display: table-cell !important;
  }
  .vsx-d-lg-flex {
    display: flex !important;
  }
  .vsx-d-lg-inline-flex {
    display: inline-flex !important;
  }
  .vsx-d-lg-none {
    display: none !important;
  }
  .vsx-flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .vsx-flex-lg-row {
    flex-direction: row !important;
  }
  .vsx-flex-lg-column {
    flex-direction: column !important;
  }
  .vsx-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .vsx-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .vsx-flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .vsx-flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .vsx-flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .vsx-flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .vsx-flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .vsx-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .vsx-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .vsx-justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .vsx-justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .vsx-justify-content-lg-center {
    justify-content: center !important;
  }
  .vsx-justify-content-lg-between {
    justify-content: space-between !important;
  }
  .vsx-justify-content-lg-around {
    justify-content: space-around !important;
  }
  .vsx-justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .vsx-align-items-lg-start {
    align-items: flex-start !important;
  }
  .vsx-align-items-lg-end {
    align-items: flex-end !important;
  }
  .vsx-align-items-lg-center {
    align-items: center !important;
  }
  .vsx-align-items-lg-baseline {
    align-items: baseline !important;
  }
  .vsx-align-items-lg-stretch {
    align-items: stretch !important;
  }
  .vsx-align-content-lg-start {
    align-content: flex-start !important;
  }
  .vsx-align-content-lg-end {
    align-content: flex-end !important;
  }
  .vsx-align-content-lg-center {
    align-content: center !important;
  }
  .vsx-align-content-lg-between {
    align-content: space-between !important;
  }
  .vsx-align-content-lg-around {
    align-content: space-around !important;
  }
  .vsx-align-content-lg-stretch {
    align-content: stretch !important;
  }
  .vsx-align-self-lg-auto {
    align-self: auto !important;
  }
  .vsx-align-self-lg-start {
    align-self: flex-start !important;
  }
  .vsx-align-self-lg-end {
    align-self: flex-end !important;
  }
  .vsx-align-self-lg-center {
    align-self: center !important;
  }
  .vsx-align-self-lg-baseline {
    align-self: baseline !important;
  }
  .vsx-align-self-lg-stretch {
    align-self: stretch !important;
  }
  .vsx-order-lg-first {
    order: -1 !important;
  }
  .vsx-order-lg-0 {
    order: 0 !important;
  }
  .vsx-order-lg-1 {
    order: 1 !important;
  }
  .vsx-order-lg-2 {
    order: 2 !important;
  }
  .vsx-order-lg-3 {
    order: 3 !important;
  }
  .vsx-order-lg-4 {
    order: 4 !important;
  }
  .vsx-order-lg-5 {
    order: 5 !important;
  }
  .vsx-order-lg-last {
    order: 6 !important;
  }
  .vsx-m-lg-0 {
    margin: 0 !important;
  }
  .vsx-m-lg-1 {
    margin: 0.25rem !important;
  }
  .vsx-m-lg-2 {
    margin: 0.5rem !important;
  }
  .vsx-m-lg-3 {
    margin: 1rem !important;
  }
  .vsx-m-lg-4 {
    margin: 1.5rem !important;
  }
  .vsx-m-lg-5 {
    margin: 3rem !important;
  }
  .vsx-m-lg-auto {
    margin: auto !important;
  }
  .vsx-mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .vsx-mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .vsx-mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .vsx-mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .vsx-mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .vsx-mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .vsx-mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .vsx-my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .vsx-my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .vsx-my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .vsx-my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .vsx-my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .vsx-my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .vsx-my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .vsx-mt-lg-0 {
    margin-top: 0 !important;
  }
  .vsx-mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .vsx-mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .vsx-mt-lg-3 {
    margin-top: 1rem !important;
  }
  .vsx-mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .vsx-mt-lg-5 {
    margin-top: 3rem !important;
  }
  .vsx-mt-lg-auto {
    margin-top: auto !important;
  }
  .vsx-me-lg-0 {
    margin-right: 0 !important;
  }
  .vsx-me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .vsx-me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .vsx-me-lg-3 {
    margin-right: 1rem !important;
  }
  .vsx-me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .vsx-me-lg-5 {
    margin-right: 3rem !important;
  }
  .vsx-me-lg-auto {
    margin-right: auto !important;
  }
  .vsx-mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .vsx-mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .vsx-mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .vsx-mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .vsx-mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .vsx-mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .vsx-mb-lg-auto {
    margin-bottom: auto !important;
  }
  .vsx-ms-lg-0 {
    margin-left: 0 !important;
  }
  .vsx-ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .vsx-ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .vsx-ms-lg-3 {
    margin-left: 1rem !important;
  }
  .vsx-ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .vsx-ms-lg-5 {
    margin-left: 3rem !important;
  }
  .vsx-ms-lg-auto {
    margin-left: auto !important;
  }
  .vsx-p-lg-0 {
    padding: 0 !important;
  }
  .vsx-p-lg-1 {
    padding: 0.25rem !important;
  }
  .vsx-p-lg-2 {
    padding: 0.5rem !important;
  }
  .vsx-p-lg-3 {
    padding: 1rem !important;
  }
  .vsx-p-lg-4 {
    padding: 1.5rem !important;
  }
  .vsx-p-lg-5 {
    padding: 3rem !important;
  }
  .vsx-px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .vsx-px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .vsx-px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .vsx-px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .vsx-px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .vsx-px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .vsx-py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .vsx-py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .vsx-py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .vsx-py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .vsx-py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .vsx-py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .vsx-pt-lg-0 {
    padding-top: 0 !important;
  }
  .vsx-pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .vsx-pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .vsx-pt-lg-3 {
    padding-top: 1rem !important;
  }
  .vsx-pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .vsx-pt-lg-5 {
    padding-top: 3rem !important;
  }
  .vsx-pe-lg-0 {
    padding-right: 0 !important;
  }
  .vsx-pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .vsx-pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .vsx-pe-lg-3 {
    padding-right: 1rem !important;
  }
  .vsx-pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .vsx-pe-lg-5 {
    padding-right: 3rem !important;
  }
  .vsx-pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .vsx-pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .vsx-pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .vsx-pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .vsx-pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .vsx-pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .vsx-ps-lg-0 {
    padding-left: 0 !important;
  }
  .vsx-ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .vsx-ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .vsx-ps-lg-3 {
    padding-left: 1rem !important;
  }
  .vsx-ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .vsx-ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .vsx-d-xl-inline {
    display: inline !important;
  }
  .vsx-d-xl-inline-block {
    display: inline-block !important;
  }
  .vsx-d-xl-block {
    display: block !important;
  }
  .vsx-d-xl-grid {
    display: grid !important;
  }
  .vsx-d-xl-table {
    display: table !important;
  }
  .vsx-d-xl-table-row {
    display: table-row !important;
  }
  .vsx-d-xl-table-cell {
    display: table-cell !important;
  }
  .vsx-d-xl-flex {
    display: flex !important;
  }
  .vsx-d-xl-inline-flex {
    display: inline-flex !important;
  }
  .vsx-d-xl-none {
    display: none !important;
  }
  .vsx-flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .vsx-flex-xl-row {
    flex-direction: row !important;
  }
  .vsx-flex-xl-column {
    flex-direction: column !important;
  }
  .vsx-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .vsx-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .vsx-flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .vsx-flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .vsx-flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .vsx-flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .vsx-flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .vsx-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .vsx-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .vsx-justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .vsx-justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .vsx-justify-content-xl-center {
    justify-content: center !important;
  }
  .vsx-justify-content-xl-between {
    justify-content: space-between !important;
  }
  .vsx-justify-content-xl-around {
    justify-content: space-around !important;
  }
  .vsx-justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .vsx-align-items-xl-start {
    align-items: flex-start !important;
  }
  .vsx-align-items-xl-end {
    align-items: flex-end !important;
  }
  .vsx-align-items-xl-center {
    align-items: center !important;
  }
  .vsx-align-items-xl-baseline {
    align-items: baseline !important;
  }
  .vsx-align-items-xl-stretch {
    align-items: stretch !important;
  }
  .vsx-align-content-xl-start {
    align-content: flex-start !important;
  }
  .vsx-align-content-xl-end {
    align-content: flex-end !important;
  }
  .vsx-align-content-xl-center {
    align-content: center !important;
  }
  .vsx-align-content-xl-between {
    align-content: space-between !important;
  }
  .vsx-align-content-xl-around {
    align-content: space-around !important;
  }
  .vsx-align-content-xl-stretch {
    align-content: stretch !important;
  }
  .vsx-align-self-xl-auto {
    align-self: auto !important;
  }
  .vsx-align-self-xl-start {
    align-self: flex-start !important;
  }
  .vsx-align-self-xl-end {
    align-self: flex-end !important;
  }
  .vsx-align-self-xl-center {
    align-self: center !important;
  }
  .vsx-align-self-xl-baseline {
    align-self: baseline !important;
  }
  .vsx-align-self-xl-stretch {
    align-self: stretch !important;
  }
  .vsx-order-xl-first {
    order: -1 !important;
  }
  .vsx-order-xl-0 {
    order: 0 !important;
  }
  .vsx-order-xl-1 {
    order: 1 !important;
  }
  .vsx-order-xl-2 {
    order: 2 !important;
  }
  .vsx-order-xl-3 {
    order: 3 !important;
  }
  .vsx-order-xl-4 {
    order: 4 !important;
  }
  .vsx-order-xl-5 {
    order: 5 !important;
  }
  .vsx-order-xl-last {
    order: 6 !important;
  }
  .vsx-m-xl-0 {
    margin: 0 !important;
  }
  .vsx-m-xl-1 {
    margin: 0.25rem !important;
  }
  .vsx-m-xl-2 {
    margin: 0.5rem !important;
  }
  .vsx-m-xl-3 {
    margin: 1rem !important;
  }
  .vsx-m-xl-4 {
    margin: 1.5rem !important;
  }
  .vsx-m-xl-5 {
    margin: 3rem !important;
  }
  .vsx-m-xl-auto {
    margin: auto !important;
  }
  .vsx-mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .vsx-mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .vsx-mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .vsx-mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .vsx-mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .vsx-mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .vsx-mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .vsx-my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .vsx-my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .vsx-my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .vsx-my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .vsx-my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .vsx-my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .vsx-my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .vsx-mt-xl-0 {
    margin-top: 0 !important;
  }
  .vsx-mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .vsx-mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .vsx-mt-xl-3 {
    margin-top: 1rem !important;
  }
  .vsx-mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .vsx-mt-xl-5 {
    margin-top: 3rem !important;
  }
  .vsx-mt-xl-auto {
    margin-top: auto !important;
  }
  .vsx-me-xl-0 {
    margin-right: 0 !important;
  }
  .vsx-me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .vsx-me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .vsx-me-xl-3 {
    margin-right: 1rem !important;
  }
  .vsx-me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .vsx-me-xl-5 {
    margin-right: 3rem !important;
  }
  .vsx-me-xl-auto {
    margin-right: auto !important;
  }
  .vsx-mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .vsx-mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .vsx-mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .vsx-mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .vsx-mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .vsx-mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .vsx-mb-xl-auto {
    margin-bottom: auto !important;
  }
  .vsx-ms-xl-0 {
    margin-left: 0 !important;
  }
  .vsx-ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .vsx-ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .vsx-ms-xl-3 {
    margin-left: 1rem !important;
  }
  .vsx-ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .vsx-ms-xl-5 {
    margin-left: 3rem !important;
  }
  .vsx-ms-xl-auto {
    margin-left: auto !important;
  }
  .vsx-p-xl-0 {
    padding: 0 !important;
  }
  .vsx-p-xl-1 {
    padding: 0.25rem !important;
  }
  .vsx-p-xl-2 {
    padding: 0.5rem !important;
  }
  .vsx-p-xl-3 {
    padding: 1rem !important;
  }
  .vsx-p-xl-4 {
    padding: 1.5rem !important;
  }
  .vsx-p-xl-5 {
    padding: 3rem !important;
  }
  .vsx-px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .vsx-px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .vsx-px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .vsx-px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .vsx-px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .vsx-px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .vsx-py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .vsx-py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .vsx-py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .vsx-py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .vsx-py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .vsx-py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .vsx-pt-xl-0 {
    padding-top: 0 !important;
  }
  .vsx-pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .vsx-pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .vsx-pt-xl-3 {
    padding-top: 1rem !important;
  }
  .vsx-pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .vsx-pt-xl-5 {
    padding-top: 3rem !important;
  }
  .vsx-pe-xl-0 {
    padding-right: 0 !important;
  }
  .vsx-pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .vsx-pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .vsx-pe-xl-3 {
    padding-right: 1rem !important;
  }
  .vsx-pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .vsx-pe-xl-5 {
    padding-right: 3rem !important;
  }
  .vsx-pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .vsx-pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .vsx-pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .vsx-pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .vsx-pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .vsx-pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .vsx-ps-xl-0 {
    padding-left: 0 !important;
  }
  .vsx-ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .vsx-ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .vsx-ps-xl-3 {
    padding-left: 1rem !important;
  }
  .vsx-ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .vsx-ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .vsx-d-xxl-inline {
    display: inline !important;
  }
  .vsx-d-xxl-inline-block {
    display: inline-block !important;
  }
  .vsx-d-xxl-block {
    display: block !important;
  }
  .vsx-d-xxl-grid {
    display: grid !important;
  }
  .vsx-d-xxl-table {
    display: table !important;
  }
  .vsx-d-xxl-table-row {
    display: table-row !important;
  }
  .vsx-d-xxl-table-cell {
    display: table-cell !important;
  }
  .vsx-d-xxl-flex {
    display: flex !important;
  }
  .vsx-d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .vsx-d-xxl-none {
    display: none !important;
  }
  .vsx-flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .vsx-flex-xxl-row {
    flex-direction: row !important;
  }
  .vsx-flex-xxl-column {
    flex-direction: column !important;
  }
  .vsx-flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .vsx-flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .vsx-flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .vsx-flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .vsx-flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .vsx-flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .vsx-flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .vsx-flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .vsx-flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .vsx-justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .vsx-justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .vsx-justify-content-xxl-center {
    justify-content: center !important;
  }
  .vsx-justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .vsx-justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .vsx-justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .vsx-align-items-xxl-start {
    align-items: flex-start !important;
  }
  .vsx-align-items-xxl-end {
    align-items: flex-end !important;
  }
  .vsx-align-items-xxl-center {
    align-items: center !important;
  }
  .vsx-align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .vsx-align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .vsx-align-content-xxl-start {
    align-content: flex-start !important;
  }
  .vsx-align-content-xxl-end {
    align-content: flex-end !important;
  }
  .vsx-align-content-xxl-center {
    align-content: center !important;
  }
  .vsx-align-content-xxl-between {
    align-content: space-between !important;
  }
  .vsx-align-content-xxl-around {
    align-content: space-around !important;
  }
  .vsx-align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .vsx-align-self-xxl-auto {
    align-self: auto !important;
  }
  .vsx-align-self-xxl-start {
    align-self: flex-start !important;
  }
  .vsx-align-self-xxl-end {
    align-self: flex-end !important;
  }
  .vsx-align-self-xxl-center {
    align-self: center !important;
  }
  .vsx-align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .vsx-align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .vsx-order-xxl-first {
    order: -1 !important;
  }
  .vsx-order-xxl-0 {
    order: 0 !important;
  }
  .vsx-order-xxl-1 {
    order: 1 !important;
  }
  .vsx-order-xxl-2 {
    order: 2 !important;
  }
  .vsx-order-xxl-3 {
    order: 3 !important;
  }
  .vsx-order-xxl-4 {
    order: 4 !important;
  }
  .vsx-order-xxl-5 {
    order: 5 !important;
  }
  .vsx-order-xxl-last {
    order: 6 !important;
  }
  .vsx-m-xxl-0 {
    margin: 0 !important;
  }
  .vsx-m-xxl-1 {
    margin: 0.25rem !important;
  }
  .vsx-m-xxl-2 {
    margin: 0.5rem !important;
  }
  .vsx-m-xxl-3 {
    margin: 1rem !important;
  }
  .vsx-m-xxl-4 {
    margin: 1.5rem !important;
  }
  .vsx-m-xxl-5 {
    margin: 3rem !important;
  }
  .vsx-m-xxl-auto {
    margin: auto !important;
  }
  .vsx-mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .vsx-mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .vsx-mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .vsx-mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .vsx-mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .vsx-mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .vsx-mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .vsx-my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .vsx-my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .vsx-my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .vsx-my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .vsx-my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .vsx-my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .vsx-my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .vsx-mt-xxl-0 {
    margin-top: 0 !important;
  }
  .vsx-mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .vsx-mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .vsx-mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .vsx-mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .vsx-mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .vsx-mt-xxl-auto {
    margin-top: auto !important;
  }
  .vsx-me-xxl-0 {
    margin-right: 0 !important;
  }
  .vsx-me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .vsx-me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .vsx-me-xxl-3 {
    margin-right: 1rem !important;
  }
  .vsx-me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .vsx-me-xxl-5 {
    margin-right: 3rem !important;
  }
  .vsx-me-xxl-auto {
    margin-right: auto !important;
  }
  .vsx-mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .vsx-mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .vsx-mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .vsx-mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .vsx-mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .vsx-mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .vsx-mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .vsx-ms-xxl-0 {
    margin-left: 0 !important;
  }
  .vsx-ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .vsx-ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .vsx-ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .vsx-ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .vsx-ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .vsx-ms-xxl-auto {
    margin-left: auto !important;
  }
  .vsx-p-xxl-0 {
    padding: 0 !important;
  }
  .vsx-p-xxl-1 {
    padding: 0.25rem !important;
  }
  .vsx-p-xxl-2 {
    padding: 0.5rem !important;
  }
  .vsx-p-xxl-3 {
    padding: 1rem !important;
  }
  .vsx-p-xxl-4 {
    padding: 1.5rem !important;
  }
  .vsx-p-xxl-5 {
    padding: 3rem !important;
  }
  .vsx-px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .vsx-px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .vsx-px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .vsx-px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .vsx-px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .vsx-px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .vsx-py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .vsx-py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .vsx-py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .vsx-py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .vsx-py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .vsx-py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .vsx-pt-xxl-0 {
    padding-top: 0 !important;
  }
  .vsx-pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .vsx-pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .vsx-pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .vsx-pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .vsx-pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .vsx-pe-xxl-0 {
    padding-right: 0 !important;
  }
  .vsx-pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .vsx-pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .vsx-pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .vsx-pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .vsx-pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .vsx-pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .vsx-pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .vsx-pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .vsx-pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .vsx-pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .vsx-pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .vsx-ps-xxl-0 {
    padding-left: 0 !important;
  }
  .vsx-ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .vsx-ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .vsx-ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .vsx-ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .vsx-ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .vsx-d-print-inline {
    display: inline !important;
  }
  .vsx-d-print-inline-block {
    display: inline-block !important;
  }
  .vsx-d-print-block {
    display: block !important;
  }
  .vsx-d-print-grid {
    display: grid !important;
  }
  .vsx-d-print-table {
    display: table !important;
  }
  .vsx-d-print-table-row {
    display: table-row !important;
  }
  .vsx-d-print-table-cell {
    display: table-cell !important;
  }
  .vsx-d-print-flex {
    display: flex !important;
  }
  .vsx-d-print-inline-flex {
    display: inline-flex !important;
  }
  .vsx-d-print-none {
    display: none !important;
  }
}
:root {
  --nav-comp-height-1: 56px;
  --nav-comp-height-2: 48px;
  --page-title-height-1: 48px;
  --dimensions-height-1: 64px;
}

body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 62.5%;
}

@-webkit-keyframes Loading_fadeIn__3XVWE {
  from {
    opacity: 0;
  }
}

@keyframes Loading_fadeIn__3XVWE {
  from {
    opacity: 0;
  }
}

@-webkit-keyframes Loading_initialLoaderAnimation__sCLUg {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0.75;
  }
}

@keyframes Loading_initialLoaderAnimation__sCLUg {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0.75;
  }
}

.Loading_loadingInContainer__2KbcO {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: Loading_initialLoaderAnimation__sCLUg 0.8s cubic-bezier(0.53, 0.22, 0.85, 0.58) 0s infinite alternate;
          animation: Loading_initialLoaderAnimation__sCLUg 0.8s cubic-bezier(0.53, 0.22, 0.85, 0.58) 0s infinite alternate;
}

.vsx-error-page {
  max-width: min(100%, 768px);
  margin: 0 auto;
}
.vsx-flexpage {
  box-sizing: border-box;
  overflow: auto;
}
.vsx-toaster {
  width: 100%;
  z-index: 9999;
  position: fixed;
  pointer-events: none;
  bottom: 8px;
  --vsx-toast-init-position: 80px;
}
.vsx-toaster.vsx-toaster-top {
  --vsx-toast-init-position: -80px;
  bottom: unset;
  top: 8px;
}
.vsx-toaster .vsx-toast {
  -webkit-user-select: none;
          user-select: none;
  transition: opacity 150ms ease-out, -webkit-transform 100ms ease-in-out;
  transition: opacity 150ms ease-out, transform 100ms ease-in-out;
  transition: opacity 150ms ease-out, transform 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
  -webkit-animation: slide-in 250ms ease-in-out;
          animation: slide-in 250ms ease-in-out;
  position: absolute;
  pointer-events: all;
}
.vsx-toaster .vsx-toast.vsx-toast-dismissed {
  opacity: 0;
}
@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    margin-left: -40px;
    margin-top: var(--vsx-toast-init-position);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    margin-left: 0;
    margin-top: 0;
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    margin-left: -40px;
    margin-top: var(--vsx-toast-init-position);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    margin-left: 0;
    margin-top: 0;
  }
}
.vsx-notification {
  box-sizing: border-box;
  background: var(--color-background-default);
  border-radius: 3px;
}
.vsx-notification.vsx-notification-border {
  outline: 1px solid var(--color-border-light);
}
.vsx-notification.vsx-notification-shadow-small {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}
.vsx-notification.vsx-notification-shadow-large {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16), 0 4px 8px 0 rgba(0, 0, 0, 0.08);
}
.vsx-notification .vsx-notification-dismiss-icon {
  cursor: pointer;
  color: var(--color-text-subdued);
}
.vsx-notification .vsx-notification-dismiss-icon:active {
  color: var(--color-text-default);
  box-shadow: 0 0 0 2px var(--color-background-light-2);
}
.vsx-notification .vsx-notification-dismiss-icon:hover {
  background-color: var(--color-background-light-2);
  color: var(--color-text-default);
}
.vsx-notification .vsx-notification-children {
  font-size: var(--font-font-size-13);
  color: var(--color-text-default);
  flex-grow: 1;
  align-self: center;
}
.vsx-notification .vsx-notification-icon {
  font-size: var(--icon-size);
}
.vsx-notification .vsx-notification-icon.info {
  color: var(--color-icon-default);
}
.vsx-notification .vsx-notification-icon.success {
  color: var(--color-icon-success);
}
.vsx-notification .vsx-notification-icon.warning {
  color: var(--color-icon-critical);
}
.vsx-notification .vsx-notification-icon.alert {
  color: #D39053;
  color: var(--color-icon-alert, #D39053);
}
.vsx-link {
  color: var(--color-text-link-default);
  cursor: pointer;
  text-decoration: unset;
}
.vsx-link:hover {
  color: var(--color-text-link-hover);
  text-decoration: underline;
}
.vsx-link:disabled {
  color: var(--color-text-subdued-light);
}
.vsx-expanding-input-button {
  display: inline-block;
  position: relative;
}

.vsx-expanding-input-button input[type='text'] {
  border-color: var(--color-border-outline-subdued);
  cursor: pointer;
  margin: 0px;
  width: 32px;
  transition: 0.4s all ease-in-out;
}

.vsx-expanding-input-button input[type='text']:hover,
.vsx-expanding-input-button.vsx-expanding-input-button-focused
  input[type='text'] {
  border-color: var(--color-border-outline-dark);
  box-shadow: none;
  outline: none;
}

.vsx-expanding-input-button.vsx-expanding-input-button-focused
  input[type='text'] {
  box-shadow: 0 0 0 2px #e6e6e6;
  cursor: auto;
  padding: 2px 32px 2px 32px;
  width: 200px;
}

.vsx-expanding-input-button .vsx-expanding-input-button-icon {
  background-color: var(--color-background-default);
  border-radius: 3px;
  pointer-events: none;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 30px;
  height: 30px;
}

.vsx-expanding-input-button .vsx-expanding-input-button-icon svg {
  fill: var(--color-icon-default);
  margin-top: 4px;
  margin-left: 6px;
  width: 18px;
  transition: 0.2s all ease-in-out;
}

.vsx-expanding-input-button:hover .vsx-expanding-input-button-icon svg,
.vsx-expanding-input-button.vsx-expanding-input-button-focused
  .vsx-expanding-input-button-icon
  svg {
  fill: var(--color-icon-default);
}

.vsx-expanding-input-button .vsx-window-close-icon {
  cursor: pointer;
  fill: var(--color-icon-default);
  position: absolute;
  right: 8px;
  top: 4px;
  width: 18px;
  transition: 0.2s all ease-in-out;
}
.vsx-expanding-input-button.vsx-expanding-input-button-focused
  .vsx-window-close-icon {
  -webkit-animation: opacity 0.8s;
          animation: opacity 0.8s;
}

.vsx-expanding-input-button .vsx-window-close-icon:hover {
  fill: var(--color-icon-dark);
}

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.vsx-pagination {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  border-top: 1px solid #e3e6e8;
}

.vsx-page-control {
  display: flex;
  align-items: center;
}

.vsx-page-control > * {
  margin: 0 4px;
}

.vsx-pagination-paging {
  font-size: 14px;
}

.vsx-rows-per-page-container {
  width: 150px;
}

.vsx-react-select__menu.vsx-react-select__menu {
  z-index: 100;
}

.vsx-react-select-rows-per-page {
  margin-bottom: 0;
}

.vsx-rows-per-page-container > div {
  margin-bottom: 0;
}
.loading-animation.loading-container {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: var(--color-text-subdued);
  font-weight: 400;
  text-align: center;
}
.loading-animation.loading-bars {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 32px;
  height: 32px;
}
.loading-animation.loading-bars div {
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 4px;
  border-radius: 2px;
  background: var(--color-primary-gray-2);
}
.loading-animation.loading-bars div:nth-child(1) {
  left: 8px;
  -webkit-animation: bar-animation 1.6s ease infinite;
          animation: bar-animation 1.6s ease infinite;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
}
.loading-animation.loading-bars div:nth-child(2) {
  left: 14px;
  -webkit-animation: bar-animation 1.6s ease infinite;
          animation: bar-animation 1.6s ease infinite;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}
.loading-animation.loading-bars div:nth-child(3) {
  left: 20px;
  -webkit-animation: bar-animation 1.6s ease infinite;
          animation: bar-animation 1.6s ease infinite;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
@-webkit-keyframes bar-animation {
  0% {
    height: 8px;
  }
  50% {
    height: 16px;
  }
  100% {
    height: 8px;
  }
}
@keyframes bar-animation {
  0% {
    height: 8px;
  }
  50% {
    height: 16px;
  }
  100% {
    height: 8px;
  }
}

.vsx-table-container {
  border: 1px solid #c8ccce;
  border-radius: 3px;
  padding: 16px;
  margin: 0 0 32px;
}
.vsx-table-container .vsx-table {
  margin-bottom: 0;
}

.vsx-table-title {
  font-weight: 500;
  font-size: var(--font-font-size-16);
  border-bottom: 0px solid #c8ccce;
  margin-bottom: 8px;
}

.vsx-table {
  border-collapse: collapse;
  text-align: left;
  margin: 0 0 32px;
  width: 100%;
  font-size: var(--font-font-size-14);
}
.vsx-table:last-child {
  margin-bottom: 0;
}
.vsx-table > tbody > tr:hover {
  background-color: #fafafa;
}
.vsx-table .vsx-thead .vsx-cell {
  border-bottom: 1px solid #c8ccce;
  vertical-align: middle;
  min-height: 32px;
  font-size: var(--font-font-size-13);
}
.vsx-table .vsx-tfoot .vsx-cell {
  border-top: 1px solid #c8ccce;
  font-size: var(--font-font-size-13);
}
.vsx-table .vsx-cell {
  border-top: 1px solid #eee;
  vertical-align: middle;
  padding: 4px;
  margin: 0;
  min-height: 32px;
}
.vsx-table .vsx-cell-right {
  text-align: right;
}
.vsx-table .vsx-cell-center {
  text-align: center;
}
.vsx-table .vsx-cell-nowrap {
  white-space: nowrap;
}

@media (max-width: 600px) {
  .vsx-cell {
    max-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .vsx-cell:first-child {
    max-width: 100px;
  }
}
/* BOOTSTRAP 3.3.6ish MODAL STYLES - NEED TO REMOVE/REPLACE */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  top: 80px;
  background-color: var(--color-background-default);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 3px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  transition: 0.2s all ease-in-out;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.modal-open {
  overflow: hidden;
}

.modal-header {
  padding: 15px 25px 15px 25px;
}

.modal-header-grid {
  display: grid;
  grid-template-columns: 1fr 36px;
  align-items: center;
}

.modal-header h1 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
}

.modal-body {
  position: relative;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  flex-grow: 1;
}

.modal-footer {
  padding: 15px 25px 15px 25px;
  text-align: right;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: " ";
}

.modal-header:after,
.modal-footer:after {
  clear: both;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
  .modal-md {
    width: 400px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
.vsx-clickable {
  color: var(--color-text-link-default);
  cursor: pointer;
}
.vsx-clickable:hover {
  color: var(--color-text-link-hover);
  text-decoration: underline;
}
.vsx-clickable:disabled {
  color: var(--color-text-subdued-light);
}
.vsx-dropdown-with-button {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  border-radius: 3px;
}
.vsx-dropdown-with-button.expanded .vsx-popover-reference > .vsx-btn {
  background: var(--color-background-light-2);
  color: var(--color-text-default);
}
.vsx-dropdown-with-button .vsx-popover .tippy-content > div:first-child {
  display: inherit;
}
.vsx-dropdown-with-button .vsx-popover-wrapper .tippy-box {
  line-height: normal;
}
.vsx-dropdown-with-button .vsx-popover-wrapper,
.vsx-dropdown-with-button .vsx-popover-reference {
  display: inline-block;
}
.vsx-dropdown-with-button .vsx-popover-wrapper.no-chevron > button,
.vsx-dropdown-with-button .vsx-popover-reference.no-chevron > button {
  padding: 4px 10px;
}
.vsx-dropdown-with-button .vsx-popover-wrapper > button:not(.vsx-icon-btn),
.vsx-dropdown-with-button .vsx-popover-reference > button:not(.vsx-icon-btn) {
  padding: 4px 4px 4px 10px;
}
.vsx-dropdown-with-button .vsx-popover-wrapper > button:not(.vsx-icon-btn) > span,
.vsx-dropdown-with-button .vsx-popover-reference > button:not(.vsx-icon-btn) > span {
  vertical-align: middle;
}
.vsx-dropdown-with-button .vsx-popover-wrapper > button:not(.vsx-icon-btn) .chevron,
.vsx-dropdown-with-button .vsx-popover-reference > button:not(.vsx-icon-btn) .chevron {
  font-size: 1.3em;
  margin-left: 0.1em;
  align-self: center;
}
.vsx-dropdown-with-button .vsx-dropdown {
  box-sizing: border-box;
  box-shadow: 1px 4px 8px 1px var(--color-background-backdrop);
  background: white;
  min-width: 96px;
}
.vsx-dropdown-with-button .vsx-dropdown .tippy-content {
  border-left: unset;
}
.vsx-bs-container {
  text-align: center;
  padding: 64px 32px;
}

.vsx-bs-container.vsx-bs-leftalign {
  display: flex;
  text-align: left;
}

.vsx-bs-container.vsx-bs-leftalign .vsx-bs-img {
  width: 128px;
}

.vsx-bs-container.vsx-bs-leftalign .vsx-bs-content {
  padding-left: 24px;
  padding-top: 16px;
}

.vsx-bs-container.vsx-bs-leftalign h1.vsx-bs-title {
  margin-top: 0;
  margin-bottom: 24px;
}

.vsx-bs-container.vsx-bs-leftalign .vsx-bs-subtitle {
  margin-bottom: 24px;
}

.vsx-bs-container.vsx-bs-leftalign .vsx-bs-subtitle p {
  margin-bottom: 8px;
}

.vsx-bs-img svg {
  width: 128px;
  height: 128px;
  fill: var(--color-icon-placeholder);
}

h1.vsx-bs-title {
  font-size: 1.8rem;
  margin-bottom: 24px;
  margin-top: 32px;
}

.vsx-bs-subtitle {
  color: var(--color-text-default);
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 8px;
}

.vsx-bs-description-text {
  color: var(--color-text-subdued);
  margin: 0 auto 24px;
  max-width: 768px;
}

.vsx-bs-button-primary {
  margin: 0 0 16px;
}

.vsx-alert-d {
  --icon-size: 32px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  flex-grow: 1 2 1;
  z-index: 9999;
  width: 75vw;
  max-width: 728px;
  background-color: white;
  left: 0;
  right: 0;
  margin: auto;
  top: 8vh;
  position: fixed;
  padding: 16px;
  box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.25);
  font-size: 14px;
}
.vsx-alert-d .vsx-alert-icon-container {
  width: var(--icon-size);
  height: var(--icon-size);
  margin-right: 16px;
  align-self: flex-start;
}
.vsx-alert-d .vsx-alert-icon {
  font-size: var(--icon-size);
}
.vsx-alert-d .vsx-alert-icon.info {
  color: var(--color-icon-default);
}
.vsx-alert-d .vsx-alert-icon.success {
  color: var(--color-icon-success);
}
.vsx-alert-d .vsx-alert-icon.warning {
  color: var(--color-icon-critical);
}
.vsx-alert-d.vsx-success-alert {
  border-left: 4px solid var(--color-background-success);
}
.vsx-alert-d.vsx-info-alert {
  border-left: 4px solid var(--color-background-dark-2);
}
.vsx-alert-d.vsx-warning-alert {
  border-left: 4px solid var(--color-icon-critical);
}
.vsx-alert-d.vsx-hidden-alert {
  display: none;
}
.vsx-alert-d .vsx-alert-content {
  width: 100%;
  line-height: 1.5;
  display: flex;
  place-items: center;
}
.vsx-alert-d .vsx-alert-close-container {
  min-height: var(--icon-size);
  margin-left: 16px;
  text-align: right;
  align-self: flex-start;
}
.vsx-alert-d .vsx-alert-close-icon {
  cursor: pointer;
  fill: var(--color-icon-default);
  margin-top: 4px;
  transition: 0.2s all ease-in-out;
}
.vsx-alert-d .vsx-alert-close-icon:hover {
  fill: var(--color-icon-dark);
}
.vsx-alert-d .vsx-alert-close-icon .vsx-alert-close-button {
  margin: 0;
}
.vsx-form-page-layout {
  box-sizing: border-box;
  --card-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 2px 3px -1px rgba(0, 0, 0, 0.08);
}
.vsx-form-page-layout.vsx-form-page-layout-padded {
  padding-top: 16px;
  padding-bottom: 16px;
}
.vsx-form-page-layout .vsx-form-page-content {
  box-shadow: var(--card-shadow);
}
.vsx-form-page-layout .vsx-form-page-content .vsx-form-page-block {
  box-sizing: border-box;
  color: inherit;
  background-color: var(--color-background-default);
  border-radius: 3px;
  padding: 32px;
  max-width: 100%;
}
.vsx-form-page-layout .vsx-form-page-content .vsx-form-page-block .vsx-form-page-block-title {
  font-weight: var(--font-font-weight-medium);
  font-size: 20px;
}
.vsx-form-page-layout .vsx-form-page-content .vsx-form-page-block .vsx-form-page-button-stub {
  visibility: hidden;
}
.vsx-form-page-layout .vsx-form-page-content.child-gap {
  box-shadow: unset;
}
.vsx-form-page-layout .vsx-form-page-content.child-gap .vsx-form-page-block {
  box-shadow: var(--card-shadow);
}
.vsx-form-page-layout .vsx-form-page-content:not(.child-gap) .vsx-form-page-block + .vsx-form-page-block {
  padding-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.vsx-form-page-layout .vsx-form-page-content:not(.child-gap) .vsx-form-page-block:has(+ .vsx-form-page-block) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vsx-cjform {
  font-size: var(--font-font-size-12);
}
.vsx-cjform .vsx-cjform-grid {
  grid-gap: 16px 12px;
  gap: 16px 12px;
}
.vsx-cjform .vsx-cjform-fullwidth {
  grid-column: 1/-1;
}
.vsx-cjform .vsx-cjform-heading {
  font-size: var(--font-font-size-16);
  font-weight: var(--font-font-weight-medium);
}
.vsx-cjform .vsx-cjform-subheading .vsx-cjform-subheading-heading {
  font-size: var(--font-font-size-14);
  font-weight: var(--font-font-weight-medium);
}
.vsx-cjform .vsx-cjform-subheading .vsx-cjform-subheading-description {
  color: var(--color-text-subdued);
  font-size: var(--font-font-size-13);
  line-height: 1.6;
}

.vsx-cjform-required-indicator {
  color: var(--color-text-critical);
}

.vsx-cjform-input-hidden {
  display: none !important;
}

.vsx-cjform-generic-input-wrapper {
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  border: 1px solid #c8ccce;
  border-radius: 3px;
  box-sizing: border-box;
  font: inherit;
  border-radius: 3px;
  height: 32px;
  line-height: 1.6;
  padding: 3px 10px;
  transition: border-color 0.2s ease-in-out;
}
.vsx-cjform-generic-input-wrapper > input {
  outline: none;
  border: none;
  font: inherit;
  flex-grow: 1;
  background: unset;
}
.vsx-cjform-generic-input-wrapper:has(input:focus) {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-cjform-generic-input-wrapper.error {
  border-color: var(--color-background-critical);
}
.vsx-cjform-generic-input-wrapper.error:has(input:focus) {
  box-shadow: 0 0 6px var(--color-background-critical);
  border-color: var(--color-background-critical);
}
.vsx-cjform-generic-input-wrapper:has(input:focus:disabled), .vsx-cjform-generic-input-wrapper:has(input:focus[readonly]) {
  outline: var(--color-border-light);
  border-color: var(--color-border-light);
  box-shadow: 0 0 0 1px var(--color-border-light);
}
.vsx-cjform-generic-input-wrapper:has(input:disabled), .vsx-cjform-generic-input-wrapper:has(input[readonly]) {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}
.vsx-cjform-generic-input-wrapper:has(input:disabled) > input:disabled, .vsx-cjform-generic-input-wrapper:has(input:disabled) > input[readonly], .vsx-cjform-generic-input-wrapper:has(input[readonly]) > input:disabled, .vsx-cjform-generic-input-wrapper:has(input[readonly]) > input[readonly] {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}

.vsx-cjform-textarea-input {
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  border: 1px solid #c8ccce;
  border-radius: 3px;
  box-sizing: border-box;
  font: inherit;
  border-radius: 3px;
  height: 32px;
  line-height: 1.6;
  padding: 3px 10px;
  transition: border-color 0.2s ease-in-out;
  height: unset;
  padding: 10px;
  resize: none;
}
.vsx-cjform-textarea-input:focus {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-cjform-textarea-input.error {
  border-color: var(--color-background-critical);
}
.vsx-cjform-textarea-input.error:focus {
  box-shadow: 0 0 6px var(--color-background-critical);
  border-color: var(--color-background-critical);
}
.vsx-cjform-textarea-input:focus:disabled, .vsx-cjform-textarea-input:focus[readonly] {
  outline: var(--color-border-light);
  border-color: var(--color-border-light);
  box-shadow: 0 0 0 1px var(--color-border-light);
}
.vsx-cjform-textarea-input:disabled, .vsx-cjform-textarea-input[readonly] {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}

.vsx-cjform-select-input {
  width: auto;
}

.vsx-cjform-radio-input,
.vsx-cjform-checkbox-input {
  margin: 0;
}

.vsx-cjform-tab.vsx-tab-content.standard {
  padding: 16px 0;
}

.vsx-cjform-tab-error-label {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.vsx-cjform-section {
  --separator: 1px solid var(--color-border-light);
  border-top: var(--separator);
  border-bottom: var(--separator);
}
.vsx-cjform-section:not(.disable-form-page-block-adjust) {
  margin-left: -32px;
  margin-right: -32px;
}
.vsx-cjform-section .vsx-cjform-section-title {
  font-size: var(--font-font-size-14);
  font-weight: var(--font-font-weight-medium);
  cursor: pointer;
}
.vsx-cjform-section .vsx-cjform-section-children {
  padding: 16px 32px;
}
.vsx-cjform-section[data-expanded=false] .vsx-cjform-section-children {
  display: none !important;
}

.vsx-cjform-section + .vsx-cjform-section {
  margin-top: -16px;
}

.vsx-cjform-section:has(+ .vsx-cjform-section) {
  border-bottom-width: 0;
}

.vsx-cjform-radio-input-group-expand {
  padding: 12px;
  background: var(--color-background-page);
  border-radius: 4px;
}
.vsx-tab-header {
  position: relative;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  background-color: white;
  overflow-x: auto;
}

.vsx-tab-header.vsx-tab-header-floating {
  position: fixed;
  box-shadow: 0 1px 2px 0 rgba(136, 136, 136, 0.15);
}

.vsx-tab-label {
  border-right: 1px solid #e1e1e1;
  padding-bottom: 4px;
  background-color: var(--color-background-default);
  transition: 0.2s color ease-in-out;
  -webkit-user-select: none;
          user-select: none;
  display: flex;
}

.vsx-tab-label.vsx-tab-label-disabled {
  color: #cecece;
  background-color: white;
}

.vsx-tab-label.vsx-tab-label-clicked {
  color: var(--color-text-default);
  padding-bottom: 0px;
}

.vsx-tab-label-content.standard {
  white-space: nowrap;
  padding: 12px 32px 8px;
  text-align: center;
}

.vsx-tab-content.standard {
  padding: 16px;
}
.vsx-field {
  border: unset;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.vsx-field-label {
  font-weight: var(--font-font-weight-medium);
}

.vsx-field-required-sign {
  color: var(--color-text-critical);
}

.vsx-field-info-error {
  color: var(--color-text-critical);
}

.vsx-field-info-help {
  color: #777;
}

.vsx-field-info-row {
  line-height: 1.6;
  margin-top: -0.3em;
}

.vsx-field-optional-label {
  color: #777;
  font-weight: unset;
  font-style: italic;
}

.vsx-input-container {
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  border: 1px solid #c8ccce;
  border-radius: 3px;
  box-sizing: border-box;
  font: inherit;
  border-radius: 3px;
  height: 32px;
  line-height: 1.6;
  padding: 3px 10px;
  transition: border-color 0.2s ease-in-out;
}
.vsx-input-container > input {
  outline: none;
  border: none;
  font: inherit;
  flex-grow: 1;
  background: unset;
}
.vsx-input-container:has(input:focus) {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-input-container.error {
  border-color: var(--color-background-critical);
}
.vsx-input-container.error:has(input:focus) {
  box-shadow: 0 0 6px var(--color-background-critical);
  border-color: var(--color-background-critical);
}
.vsx-input-container:has(input:focus:disabled), .vsx-input-container:has(input:focus[readonly]) {
  outline: var(--color-border-light);
  border-color: var(--color-border-light);
  box-shadow: 0 0 0 1px var(--color-border-light);
}
.vsx-input-container:has(input:disabled), .vsx-input-container:has(input[readonly]) {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}
.vsx-input-container:has(input:disabled) > input:disabled, .vsx-input-container:has(input:disabled) > input[readonly], .vsx-input-container:has(input[readonly]) > input:disabled, .vsx-input-container:has(input[readonly]) > input[readonly] {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}
.rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.rdrDateDisplay{
  display: flex;
  justify-content: space-between;
}

.rdrDateDisplayItem{
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
}

.rdrDateDisplayItem + .rdrDateDisplayItem{
    margin-left: 0.833em;
  }

.rdrDateDisplayItem input{
    text-align: inherit
  }

.rdrDateDisplayItem input:disabled{
      cursor: default;
    }

.rdrDateDisplayItemActive{}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
}

.rdrMonthAndYearPickers{
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrMonthPicker{}

.rdrYearPicker{}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrPprevButton {}

.rdrNextButton {}

.rdrMonths{
  display: flex;
}

.rdrMonthsVertical{
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div{
  display: flex;
  flex-direction: row;
}

.rdrMonth{
  width: 27.667em;
}

.rdrWeekDays{
  display: flex;
}

.rdrWeekDay {
  flex-basis: calc(100% / 7);
  box-sizing: inherit;
  text-align: center;
}

.rdrDays{
  display: flex;
  flex-wrap: wrap;
}

.rdrDateDisplayWrapper{}

.rdrMonthName{}

.rdrInfiniteMonths{
  overflow: auto;
}

.rdrDateRangeWrapper{
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.rdrDateInput {
  position: relative;
}

.rdrDateInput input {
    outline: none;
  }

.rdrDateInput .rdrWarning {
    position: absolute;
    font-size: 1.6em;
    line-height: 1.6em;
    top: 0;
    right: .25em;
    color: #FF0000;
  }

.rdrDay {
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer;
}

.rdrDayNumber {
  display: block;
  position: relative;
}

.rdrDayNumber span{
    color: #1d2429;
  }

.rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  pointer-events: none;
}

.rdrInRange{}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  pointer-events: none;
}

.rdrDayHovered{}

.rdrDayActive{}

.rdrDateRangePickerWrapper{
  display: inline-flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.rdrDefinedRangesWrapper{}

.rdrStaticRanges{
  display: flex;
  flex-direction: column;
}

.rdrStaticRange{
  font-size: inherit;
}

.rdrStaticRangeLabel{}

.rdrInputRanges{}

.rdrInputRange{
  display: flex;
}

.rdrInputRangeInput{}

.rdrCalendarWrapper{
  color: #000000;
  font-size: 12px;
}

.rdrDateDisplayWrapper{
  background-color: rgb(239, 242, 247);
}

.rdrDateDisplay{
  margin: 0.833em;
}

.rdrDateDisplayItem{
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.rdrDateDisplayItem input{
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    color: #849095;
  }

.rdrDateDisplayItemActive{
  border-color: currentColor;
}

.rdrDateDisplayItemActive input{
    color: #7d888d
  }

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers{
  font-weight: 600;
}

.rdrMonthAndYearPickers select{
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: #3e484f;
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    text-align: center
  }

.rdrMonthAndYearPickers select:hover{
      background-color: rgba(0,0,0,0.07);
    }

.rdrMonthPicker, .rdrYearPicker{
  margin: 0 5px
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #EFF2F7
}

.rdrNextPrevButton:hover{
    background: #E1E7F0;
  }

.rdrNextPrevButton i {
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
  }

.rdrPprevButton i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0px);
  }

.rdrNextButton i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
  }

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth{
  padding: 0 0.833em 1.666em 0.833em;
}

.rdrMonth .rdrWeekDays {
    padding: 0;
  }

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
  display: none;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: rgb(132, 144, 149);
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 0;
  padding: 0;
  line-height: 3.000em;
  height: 3.000em;
  text-align: center;
  color: #1d2429
}

.rdrDay:focus {
    outline: 0;
  }

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span{
  font-weight: 500
}

.rdrDayToday .rdrDayNumber span:after{
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #3d91ff;
  }

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
      background: #fff;
    }

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
          color: rgba(255, 255, 255, 0.85);
        }

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  background: currentColor;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

.rdrSelected{
  left: 2px;
  right: 2px;
}

.rdrInRange{}

.rdrStartEdge{
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge{
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected{
  border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }

.rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    border-left-width: 1px;
    left: 0px;
  }

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
   border-top-right-radius: 1.333em;
   border-bottom-right-radius: 1.333em;
   border-right-width: 1px;
   right: 0px;
 }

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1;
}

.rdrDayStartPreview{
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0px;
}

.rdrDayInPreview{
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview{
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 2px;
  right: 0px;
}

.rdrDefinedRangesWrapper{
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected{
    color: currentColor;
    font-weight: 600;
  }

.rdrStaticRange{
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff
}

.rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
      background: #eff2f7;
    }

.rdrStaticRangeLabel{
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges{
  padding: 10px 0;
}

.rdrInputRange{
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput{
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122)
}

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
    border-color: rgb(180, 191, 196);
    outline: 0;
    color: #333;
  }

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
  content: '';
  border: 1px solid currentColor;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive{
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span{
    color: #d5dce0;
  }

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
    display: none;
  }

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);
}

.rdrDayDisabled .rdrDayNumber span{
    color: #aeb9bf;
  }

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
    filter: grayscale(100%) opacity(60%);
  }

.rdrMonthName{
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}

.vsx-datetime-picker {
  border: 1px solid #bfc0c2;
  border-radius: 3px;
  overflow: hidden;
}
.vsx-datetime-picker .rdrDateDisplayWrapper {
  display: none;
}
.vsx-datetime-picker .rdrMonthAndYearWrapper {
  padding-top: 5;
  height: unset;
}
.vsx-datetime-picker .rdrMonth {
  padding-bottom: 0.833em;
}
.vsx-datetime-picker .rdrCalendarWrapper {
  border-radius: 3px;
}
.vsx-datetime-picker .vsx-time-picker {
  border-left: 1px solid #bfc0c2;
  overflow: hidden;
}
.vsx-datetime-picker .vsx-time-picker .vsx-render-time-item {
  color: #1d2429;
  font-size: 12px;
}
.vsx-datetime-picker .vsx-time-picker .vsx-render-time-item.disabled {
  background: rgb(248, 248, 248);
  color: #aeb9bf;
  cursor: not-allowed !important;
}
.vsx-datetime-picker .vsx-time-picker.disabled {
  background: rgba(248, 248, 248, 0.85);
  cursor: not-allowed !important;
}
.vsx-datetime-picker .vsx-time-picker.disabled .vsx-time-list {
  background: rgba(248, 248, 248, 0.85);
}
.vsx-datetime-picker .vsx-time-picker.disabled .vsx-render-time-item {
  cursor: not-allowed !important;
}
.vsx-datetime-picker .vsx-time-picker.disabled .vsx-render-time-item {
  background: rgb(248, 248, 248);
  color: #aeb9bf;
}
.vsx-datetime-picker .vsx-time-picker.disabled .vsx-render-time-item.selected {
  -webkit-filter: grayscale(100%) opacity(60%);
          filter: grayscale(100%) opacity(60%);
}
.vsx-datetime-picker .vsx-time-picker .vsx-time-header {
  box-sizing: border-box;
  height: 45px;
  border-bottom: 1px solid #bfc0c2;
}
.vsx-datetime-picker .vsx-time-picker .vsx-time-list {
  overflow-y: scroll;
  min-height: 210px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
}
.vsx-datetime-picker .vsx-time-picker .vsx-time-list .vsx-render-time-item {
  padding: 5px 20px 5px 10px;
  text-align: right;
  -webkit-user-select: none;
          user-select: none;
}
.vsx-datetime-picker .vsx-time-picker .vsx-time-list .vsx-render-time-item.selected {
  background: rgb(61, 145, 255);
  color: rgba(255, 255, 255, 0.85);
}
.vsx-list-view {
  --item-padding: 7px 8px;
  background-color: var(--color-background-default);
  border-radius: 3px;
}
.vsx-list-view > :first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.vsx-list-view > :last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.vsx-list-view:not(.no-separator) > :not(:last-child) {
  border-bottom: 1px solid #c8ccce;
}
.vsx-list-view .vsx-list-item,
.vsx-list-view .vsx-list-view-header,
.vsx-list-view .vsx-list-view-footer {
  padding: var(--item-padding);
}
.vsx-list-view .vsx-list-item-clickable:hover {
  cursor: pointer;
  background: #eee;
  transition: background linear 0.2s;
}
.vsx-list-view .vsx-list-view-spinner-wrapper {
  text-align: center;
  padding: 50px 0px;
}
.vsx-list-view.no-separator > .vsx-list-item {
  border-bottom: unset;
}
.vsx-multi-file-upload {
  color: var(--color-text-default);
}
.vsx-multi-file-upload .vsx-multi-file-upload-list {
  border: 1px solid #c8ccce;
  border-radius: 3px;
  box-sizing: border-box;
  border-radius: 3px;
  min-height: 32px;
  position: relative;
}
.vsx-multi-file-upload .vsx-multi-file-upload-list.vsx-multi-file-upload-list-error {
  border-color: var(--color-text-critical);
}
.vsx-multi-file-upload .vsx-multi-file-upload-button {
  min-width: unset;
}
.vsx-multi-file-upload .vsx-multi-file-upload-remove-button {
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.vsx-multi-file-upload .vsx-multi-file-upload-info {
  flex-grow: 1;
  text-align: right;
  margin-top: 4px;
}
.vsx-multi-file-upload .vsx-multi-file-upload-filename {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.vsx-multi-file-upload .vsx-multi-file-upload-placeholder {
  text-align: center;
  font-style: italic;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: var(--color-text-subdued);
  width: calc(100% - 8px);
  top: 50%;
  left: 50%;
}
.vsx-file-drop-zone[data-active=true] {
  outline: 3px dashed var(--color-interactive-default);
  outline-offset: -3px;
}
.vsx-file-drop-zone.vsx-file-drop-zone-block {
  display: block;
}
.vsx-file-drop-zone.vsx-file-drop-zone-inline {
  display: inline-block;
}
.vsx-single-file-upload > input {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}
.vsx-single-file-upload input:disabled + .vsx-single-file-upload-button,
.vsx-single-file-upload input[readonly] + .vsx-single-file-upload-button {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}
.vsx-single-file-upload .vsx-single-file-upload-button {
  height: 20px;
}
.vsx-single-file-upload .vsx-single-file-upload-text {
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.vsx-single-file-upload .vsx-single-file-upload-text.vsx-single-file-upload-placeholder {
  font-style: italic;
  color: var(--color-text-subdued);
}
.vsx-slider {
  --main-color: var(--color-secondary-gray-2);
  --track-color: var(--color-background-light-3);
  --slider-height: 18px;
  cursor: pointer;
  min-width: 100px;
  min-height: var(--slider-height);
  flex-grow: 1;
}
.vsx-slider.vsx-slider-disabled {
  opacity: 0.4;
}
.vsx-slider.vsx-slider-range .vsx-slider-track.vsx-slider-track-0 {
  background: var(--track-color);
}
.vsx-slider.vsx-slider-range .vsx-slider-track.vsx-slider-track-1 {
  background: var(--main-color);
}
.vsx-slider .vsx-slider-track {
  top: 8px;
  height: 4px;
  background: var(--track-color);
}
.vsx-slider .vsx-slider-track.vsx-slider-track-0 {
  background: var(--main-color);
}
.vsx-slider:hover .vsx-slider-thumb {
  outline: 1px solid var(--color-border-outline-dark);
}
.vsx-slider .vsx-slider-thumb {
  background: var(--color-icon-light);
  width: var(--slider-height);
  height: var(--slider-height);
  border-radius: 100%;
  outline: 1px solid var(--color-border-outline-subdued);
  outline-offset: -1px;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}
.vsx-slider .vsx-slider-mark {
  cursor: pointer;
  top: 6px;
  width: 1.5px;
  height: 8px;
  background-color: var(--mark-color);
}
.vsx-input-element {
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  border: 1px solid #c8ccce;
  border-radius: 3px;
  box-sizing: border-box;
  font: inherit;
  border-radius: 3px;
  height: 32px;
  line-height: 1.6;
  padding: 3px 10px;
  transition: border-color 0.2s ease-in-out;
}
.vsx-input-element:focus {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}
.vsx-input-element.error {
  border-color: var(--color-background-critical);
}
.vsx-input-element.error:focus {
  box-shadow: 0 0 6px var(--color-background-critical);
  border-color: var(--color-background-critical);
}
.vsx-input-element:focus:disabled, .vsx-input-element:focus[readonly] {
  outline: var(--color-border-light);
  border-color: var(--color-border-light);
  box-shadow: 0 0 0 1px var(--color-border-light);
}
.vsx-input-element:disabled, .vsx-input-element[readonly] {
  color: var(--color-deprecating-vs-gray-background-disabled-2);
  background: var(--color-background-light-2);
  cursor: unset;
}
.vsx-social-icon {
  display: inline-block;
  line-height: 1;
}
.vsx-toggle-switch {
  font-weight: var(--font-font-weight-medium);
}
.vsx-toggle-switch.vsx-toggle-switch-disabled, .vsx-toggle-switch.vsx-toggle-switch-readonly {
  opacity: 0.4;
}
.vsx-toggle-switch.vsx-toggle-switch-disabled .vsx-toggle-input *, .vsx-toggle-switch.vsx-toggle-switch-readonly .vsx-toggle-input * {
  cursor: not-allowed !important;
}
.vsx-toggle-switch.vsx-toggle-switch-disabled .vsx-toggle-switch-label, .vsx-toggle-switch.vsx-toggle-switch-readonly .vsx-toggle-switch-label {
  cursor: not-allowed !important;
}
.vsx-toggle-switch .vsx-toggle-switch-label {
  cursor: pointer;
}
.vsx-toggle-switch .vsx-toggle-input {
  position: relative;
  display: inline-block;
  width: 30px;
  min-width: 30px;
  height: 12px;
  margin: 2px 1px;
}
.vsx-toggle-switch .vsx-toggle-input > input {
  opacity: 0;
  width: 0;
  height: 0;
}
.vsx-toggle-switch .vsx-toggle-input > input:checked + .vsx-toggle-input-slider::before {
  -webkit-transform: translateY(-2px) translateX(calc(30px - 100% + 1px));
          transform: translateY(-2px) translateX(calc(30px - 100% + 1px));
}
.vsx-toggle-switch .vsx-toggle-input > input:checked + .vsx-toggle-input-slider {
  background-color: var(--color-interactive-default);
}
.vsx-toggle-switch .vsx-toggle-input .vsx-toggle-input-slider {
  position: absolute;
  border-radius: 100px;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-border-form-default);
  transition: 0.4s;
}
.vsx-toggle-switch .vsx-toggle-input .vsx-toggle-input-slider::before {
  background-color: var(--color-text-white);
  border: 1px solid var(--color-border-form-default);
  border-radius: 45px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  content: "";
  height: 16px;
  position: absolute;
  transition: 0.2s;
  width: 16px;
  -webkit-transform: translateY(-2px) translateX(-1px);
          transform: translateY(-2px) translateX(-1px);
}
.vsx-page-heading {
  background-color: white;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  padding: 0 16px;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 3px 4px -1px rgba(0, 0, 0, 0.08);
  z-index: 2;
}

@media (min-width: 40em) {
  .vsx-page-heading--title {
    width: calc(100% - 256px);
  }
  .vsx-application-layout-side-collapsed + .vsx-application-layout-content .vsx-page-heading--title {
    width: calc(100% - 48px);
  }
}
.vsx-page-heading--section {
  right: 1em;
  display: flex;
  position: fixed;
}

.vsx-page-heading--section,
.vsx-page-heading--title,
.vsx-page-heading--section > *,
.vsx-page-heading--title > * {
  font-size: 14px;
  font-weight: 400;
}

.vsx-page-heading--description {
  border-left: 1px solid rgb(195, 195, 195);
  font-size: 13px;
  font-weight: 400;
  margin-left: 16px;
  padding-left: 16px;
}
